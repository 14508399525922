import React from 'react'
import MaterialTable from 'material-table'
import {  Button, Typography, Snackbar } from '@material-ui/core';
import { url } from './constant'
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Alert from './Alert'




class ExpertsPoints extends React.Component{
    constructor(){
        super();
        this.state={
            column:[
                {
                    "title": 'Name',
                    "field": "expertName"
                },
                {
                    "title": 'Email',
                    "field": "expertEmail",
                    // "align": "center"
                },
                  {
                    "title": 'Phone',
                    "field": "expertPhone"
                },
                {
                    "title": 'City',
                    "field": "expertCity"
                },                  
                {
                    "title": 'Username',
                    "field": "expertUserID"
                },                           
                {
                    "title": 'Total Earned',
                    "field": "expertRewardTotal"
                },                         
                {
                    "title": 'Redeemed so far',
                    "field": "expertRewardPaid"
                },           
                {
                    "title": 'Points Balance',
                    "field": "expertRewardBalance"
                },

                   {
                    title: "Action",
                    align: "center",
                    render: rowData => 
                            // <ToggleButtonGroup
                            //     value={rowData.expertStatus}
                            //     exclusive
                            //     onChange={(e, value)=>this.updateExpertStatus(rowData, e, value)}
                            //     aria-label="text alignment"
                            //     size='small'
                            // >                                
                            //     <ToggleButton value="Pending" aria-label="left aligned" variant="outlined">
                            //         Pending
                            //     </ToggleButton>
                            //     <ToggleButton value="Approved" aria-label="centered" variant="contained">
                            //         Approved
                            //     </ToggleButton>
                           
                            // </ToggleButtonGroup>

                    <Button variant="contained">Pay Now</Button>
                     },


                // {
                //     "title": 'Joined date',
                //     "field": "joinedDate"
                // }
            ],
            data: []
        }
    }


        updateExpertStatus  = (d, e, newAlignment) =>{
        let data = this.state.data;        
        let id = d["_id"]["$oid"]        
        
        fetch(url+"/updateExpertStatus", {
            method:"POST",
            body: JSON.stringify({
                "id": id,
                "expertStatus": newAlignment
            })
        })
        .then(res => res.json())
        .then((resData)=>{
            if(resData["status"]== "okay"){
                let index = data.findIndex((item) => item["_id"]["$oid"]==id );
                let item = data[index]

                item.expertStatus = newAlignment
                data[index] = item
                this.setState({ data })

                    this.setState({      
                    setSnack:true,
                    message:'Updated successfully',
                    msgstatus:"success"
                })  


            }
        })
    }

  handleClose = e =>{
        this.setState({
          setSnack: null 
        })
    }

    componentDidMount(){
        fetch(url+'/getExperts',{
            method: "GET"
        })
        .then((res)=> res.json())
        .then((data)=>{
            this.setState({
                data: data['response']
            })
        })


    }

    render(){
        const { data, column } = this.state
        return(
            <div style={{ width: "100%" }} >
                <MaterialTable 
                    columns={column} 
                    data={data} 
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10,
                        exportButton: true
                    }}                    
                    title="Experts' Points"                    
                />

                    <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>


            </div>
        )
    }
}

export default ExpertsPoints;