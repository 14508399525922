import React from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { Snackbar } from '@material-ui/core';
import { url } from './constant'
import Alert from './Alert'

class Cuisine extends React.Component{
    constructor(){
        super()
        this.state = {
            columns: [                
                {
                    title: "Cuisine name",
                    field: "title",
                }
            ],
            data: [],
            loading: true                    
        }
    }

    componentDidMount(){
        this.getdata()
    }

    getdata=()=>{
        this.setState({
            loading: true
        })
        fetch(url+"/getCuisine",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    data: data['response'],
                    loading: false
                })
            }else{                
                this.setState({        
                    setSnack:true,
                    loading: false,
                    message:'Something went wrong',
                    msgstatus:"error"
                })
            }
        })
        .catch(err =>{
            this.setState({        
                setSnack:true,
                loading: false,
                message:'Something went wrong',
                msgstatus:"error"
            })
        })
    }

    addNew = (data) =>{                        
        if(data['title']){
            fetch(url+"/addCuisine",{
                method: "POST",
                body: JSON.stringify({"title": data.title, "data": data.title })
            })
            .then(res => res.json())
            .then((data)=>{
                if(data['status']=='okay' && data['response']=='added'){                
                    this.getdata()
                    this.setState({        
                        setSnack:true,
                        message:'New cuisine added',
                        msgstatus:"success"
                    })
                } else if(data['status']=='okay' && data['response']=='exist'){
                    this.setState({        
                        setSnack:true,
                        message:'Cuisine already exist',
                        msgstatus:"error"
                    })             
                }
                else{
                    this.setState({        
                        setSnack:true,
                        message:'Something went wrong',
                        msgstatus:"error"
                    })
                }
            })
            .catch(err =>{
                this.setState({        
                    setSnack:true,
                    message:'Something went wrong',
                    msgstatus:"error"
                })
            })
        }else{
            this.setState({        
                setSnack:true,
                message:'Cuisine name should not be empty',
                msgstatus:"error"
            })        
        }
    }

    editData = (newData, oldData) =>{
        let id = newData['_id']['$oid']       
        delete newData["_id"]
        console.log(oldData, newData)

        if(newData['title']!=''){
            if(JSON.stringify(newData['title'])!=JSON.stringify(oldData['title'])){
                fetch(url+"/editCuisine",{
                    method: "POST",
                    body: JSON.stringify({ id: id, "title": newData.title })
                })
                .then(res => res.json())
                .then((data)=>{
                    if(data['status']=='okay' && data['response']=='edited'){                
                        this.getdata()
                        this.setState({        
                            setSnack:true,
                            message:'Cuisine edited',
                            msgstatus:"success"
                        })
                    } else if(data['status']=='okay' && data['response']=='exist'){
                        this.setState({        
                            setSnack:true,
                            message:'Cuisine already exist',
                            msgstatus:"error"
                        })             
                    } 
                    else if(data['status']=='err'){
                        this.setState({        
                            setSnack:true,
                            message:'Something went wrong',
                            msgstatus:"error"
                        })
                    }
                })
                .catch((err)=>{
                    this.setState({        
                        setSnack:true,
                        message:'Something went wrong',
                        msgstatus:"error"
                    })
                })
            }            
        }else {
            this.setState({        
                setSnack:true,
                message:'Cuisine name should not be empty',
                msgstatus:"error"
            })
        }
    }

    deleteData = (newData) =>{
        fetch(url+"/deleteCuisine",{
            method: "POST",
            body: JSON.stringify({"id": newData['_id']['$oid']})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({        
                    setSnack:true,
                    message:'Cuisine removed',
                    msgstatus:"info"
                })
                this.getdata()
            }        
            else{
                this.setState({        
                    setSnack:true,
                    message:'Something went wrong',
                    msgstatus:"error"
                })
            }
        })
        .catch((err)=>{
            this.setState({        
                setSnack:true,
                message:'Something went wrong',
                msgstatus:"error"
            })
        })
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    render(){
        const { columns, data, loading } = this.state
        return(
            <div style={{width: "100%"}}>
                <MaterialTable 
                    title="Manage Cuisines"
                    columns={columns} 
                    data={data} 
                    isLoading={loading}
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10,
                        actionsColumnIndex: -1                    
                    }}                    
                    editable ={{                      
                        onRowUpdate: (newdata, olddata) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.editData(newdata, olddata)
                                    resolve();
                                }, 10);
                            }),                        
                        onRowAdd: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.addNew(newData)                                                        
                                    resolve();
                                }, 100)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {                    
                                    this.deleteData(oldData)
                                    resolve();
                                }, 100);
                            })
                        }}                    
                />
                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Cuisine;