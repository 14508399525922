import React from 'react'
import MaterialTable from 'material-table';
import {  Snackbar } from '@material-ui/core'
import { url } from './constant'
import Alert from './Alert'

class FeaturesTags extends React.Component{
    constructor(){
        super()
        this.state = {
            columns: [
                {
                    title: "Features & tags",
                    field: "ft"
                }
            ],
            data: [],
            loading: true
        }
    }

    componentDidMount(){
        this.getdata()
    }

    getdata=()=>{
        this.setState({
            loading: true
        })
        fetch(url+"/getFt",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    data: data['response'],
                    loading: false
                })
            }        
        })
        .catch((er)=>{
            this.setState({                  
                loading: false
            })
        })
    }

    addNew = (newData) =>{
        fetch(url+"/addFt",{
            method: "POST",
            body: JSON.stringify(newData)
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay' && data['response']=='added'){                
                this.setState({        
                    setSnack:true,
                    message:'New Feature tag added',
                    msgstatus:"success"
                })
                this.getdata()
            } else if(data['status']=='okay' && data['response']=='exist'){                
                this.setState({        
                    setSnack:true,
                    message:'Feture tag already exist',
                    msgstatus:"error"
                })
            }
            else if(data['status']=='err'){
                this.setState({        
                    setSnack:true,
                    message:'Something went wrong',
                    msgstatus:"error"
                })
            }
        })
    }

    editData = (newData, oldData) =>{
        let id = newData['_id']['$oid']
        delete newData['_id']
        
        if(newData['ft']!=''){
            if(JSON.stringify(newData['ft']) != JSON.stringify(oldData['ft'])){
                fetch(url+"/editFt",{
                    method: "POST",
                    body: JSON.stringify({ id: id, data: newData})
                })
                .then(res => res.json())
                .then((data)=>{
                    if(data['status']=='okay' && data['response']=='edited'){                
                        this.setState({        
                            setSnack:true,
                            message:'Feature tag updated',
                            msgstatus:"success"
                        })
                        this.getdata()
                    } else if(data['status']=='okay' && data['response']=='exist'){                
                        this.setState({        
                            setSnack:true,
                            message:'Feature tag already exist',
                            msgstatus:"error"
                        })
                    }
                    else if(data['status']=='err'){
                        this.setState({        
                            setSnack:true,
                            message:'Something went wrong',
                            msgstatus:"error"
                        })
                    }            
                })      
            } 
        }else{
            this.setState({        
                setSnack:true,
                message:'Feature tag name should not be empty',
                msgstatus:"error"
            })
        }
    }

    deleteData = (newData) =>{

        fetch(url+"/deleteFt",{
            method: "POST",
            body: JSON.stringify({"id": newData['_id']['$oid']})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.getdata()
                this.setState({        
                    setSnack:true,
                    message:'Feature tag removed',
                    msgstatus:"info"
                })
            }        
        })
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }
    
    render(){
        const { columns, data, loading } = this.state
        return(
            <div style={{width: "100%"}}>            
                <MaterialTable 
                    columns={columns} 
                    data={data} 
                    isLoading={loading}
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10,
                        actionsColumnIndex: -1
                    }}
                    title="Manage Features & tags"
                    editable ={{                      
                        onRowUpdate: (newdata, olddata) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.editData(newdata, olddata)
                                    resolve();
                                }, 10);
                            }),                        
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.addNew(newData)                                                        
                                    resolve();
                                }, 100)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {                    
                                    this.deleteData(oldData)
                                    resolve();
                                }, 100);
                            })
                        }}
                />
                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default FeaturesTags