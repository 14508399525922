import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import store from 'store';
import { List, DialogTitle, Dialog, DialogActions, Button } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ListItemText from '@material-ui/core/ListItemText';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import PropTypes from 'prop-types';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ApartmentIcon from '@material-ui/icons/Apartment';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import RateReviewIcon from '@material-ui/icons/RateReview';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import StarIcon from '@material-ui/icons/Star';

import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

// import store from 'store'

import Login from './Login'
import DashBoard from './Dashboard'
import Restaurent from './Restaurent'
import AddNewRestaurent from './AddNewRestaurant'
import Users from './Users'
import Experts from './Experts'
import ExpertSignUp from './ExpertSignUp'

import ExpertsPoints from './ExpertsPoints'
import ExpertsPointsSettings from './ExpertsPointsSettings'



import ViewRes from './AllRestaurants'
import AddNewPromotions from './AddNewPromotions'
import Cuisine from './cuisines'
import PromotionPage from './PromotionPage'
import FoodSpeciality from './FoodSpeciality';
import Review from './Review'

import OFCLogo from '../logo512.svg'
import FeaturesTags from './FeaturesTags';
import { area, cuisines } from './constant'
import Areas from './Areas';
import AllPromo from './AllPromos';
import AllHawkers from './AllHawkers';
import AllCoffeeshops from './AllCoffeeshops'
import PromoTypes from './PromoTypes';
import AddNewReview from './AddNewReview'
import AllReviews from './AllReviews';

import AddExpertReview from './AddExpertReview'
import AllExpertReviews from './AllExpertReviews';


import ResPromo from './ResPromo';
// import HawkerCenter from './HawkerCenter';
import Delivery from './Delivery';

import ReviewPage from './ReviewPage';

import ExpertReviewPage from './ExpertReviewPage';

import City from './City';
import Category from './Category';


const drawerWidth = 255;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:  'center',
    ...theme.mixins.toolbar,    
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    padding: "10px 20px 20px 20px",
    display: 'flex'
  },
  itemSelected: {
    backgroundColor: "#bdbdbd"
  },
  itemNotSelected: {
    backgroundColor: "#ffffff"
  }
}));

function MainPage(props){
  const classes = useStyles();
  const path = props.history.location.pathname;
  const [login, setLogin ] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [ openDialog, setOpenDilaog ] = React.useState(false);


  const handleClose = () => {
    setOpenDilaog(false);
  };

  useEffect(() => {
  },[props.history]);

  const logout = () =>{
    store.clearAll()
    props.history.push("/")
    window.location.reload();
  }




return (
    <div>
    <div className={classes.root}>
      <Drawer
       variant="permanent" open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={OFCLogo} style={{height: 65}} />          
        </div>
        <Divider /> 
        
        <TreeView 
          defaultExpanded={['1', '7', '13', '19', '23']}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
        >
          
          <StyledTreeItem nodeId="1" labelText="Restaurants" labelIcon={FastfoodIcon}>
            <StyledSubItem
              className={ path === '/' ? classes.itemSelected : classes.itemNotSelected}              
              nodeId="3" 
              labelText="All restaurants" 
              onClick={()=> props.history.push('/')} 
              labelIcon={FiberManualRecordIcon} 
            />
            <StyledSubItem 
              className={ path==='/addnewrestaurant' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="2" 
              labelText="Add new" 
              onClick={()=> props.history.push("/addnewrestaurant")} 
              labelIcon={FiberManualRecordIcon} 
            />            
            <StyledSubItem 
              className={ path==='/rescuisines' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="4" labelText="Cuisines" onClick={()=> props.history.push('/rescuisines')} labelIcon={FiberManualRecordIcon} />
            <StyledSubItem 
              className={ path==='/resarea' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="5" labelText="Area" labelIcon={FiberManualRecordIcon} onClick={()=> props.history.push('/resarea')} 
            />
            <StyledSubItem 
              className={ path==='/city' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="9" labelText="City" labelIcon={FiberManualRecordIcon} onClick={()=> props.history.push('/city')} 
            />
            <StyledSubItem           
              className={ path==='/resfeaturesTags' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="6" labelText="Features / Tags" onClick={()=>props.history.push("/resfeaturesTags")} labelIcon={FiberManualRecordIcon} 
            />
            <StyledSubItem           
              className={ path==='/foodSpeciality' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="7" labelText="Food Speciality" onClick={()=>props.history.push("/foodSpeciality")} labelIcon={FiberManualRecordIcon} 
            />
            <StyledSubItem           
              className={ path==='/categories' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="8" labelText="Category" onClick={()=>props.history.push("/categories")} labelIcon={FiberManualRecordIcon} 
            />
          </StyledTreeItem>
            
          <StyledTreeItem nodeId="19" labelText="Promotions" labelIcon={LocalOfferIcon} >
              <StyledSubItem 
              className={ path==='/addNewPromotions' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="20" labelText="Add new" onClick={()=> props.history.push('/addNewPromotions') } labelIcon={FiberManualRecordIcon} />
              <StyledSubItem 
              className={ path==='/allPromos' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="21" labelText="All promotions"  labelIcon={FiberManualRecordIcon} onClick={()=> props.history.push('/allPromos')} />
              <StyledSubItem 
              className={ path==='/promoTypes' ? classes.itemSelected : classes.itemNotSelected} 
              nodeId="22" labelText="Promotions types" onClick={()=> props.history.push('/promoTypes')}  labelIcon={FiberManualRecordIcon} />
            </StyledTreeItem>

          <StyledTreeItem nodeId="23" className={classes.itemNotSelected} labelText="Public Reviews" onClick={()=> props.history.push('/addNewReview')} labelIcon={RateReviewIcon}  >
            <StyledSubItem 
            className={ path==='/addNewReview' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="24" labelText="Add new" onClick={()=> props.history.push('/addNewReview')} labelIcon={FiberManualRecordIcon} />
            <StyledSubItem 
            className={ path=== '/allReviews' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="25" labelText="All reviews" onClick={()=> props.history.push('/allReviews')} labelIcon={FiberManualRecordIcon} />            
          </StyledTreeItem>
          
          <StyledTreeItem 
          className={ path==='/users' ? classes.itemSelected : classes.itemNotSelected} 
          nodeId="26" labelText="Users" onClick={()=> props.history.push('/users')}  labelIcon={SupervisorAccountIcon} ></StyledTreeItem>   

                <StyledTreeItem 
          className={ path==='/experts' ? classes.itemSelected : classes.itemNotSelected} 
          nodeId="27" labelText="Experts" onClick={()=> props.history.push('/experts')}  labelIcon={StarIcon} >

                <StyledSubItem 
            className={ path=== '/experts' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="27.1" labelText="All Experts" onClick={()=> props.history.push('/experts')} labelIcon={FiberManualRecordIcon} />    

      <StyledSubItem 
            className={ path=== '/expertsPoints' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="27.2" labelText="Points" onClick={()=> props.history.push('/expertsPoints')} labelIcon={FiberManualRecordIcon} />    

                  <StyledSubItem 
            className={ path=== '/expertsPointsSettings' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="27.3" labelText="Points Settings" onClick={()=> props.history.push('/expertsPointsSettings')} labelIcon={FiberManualRecordIcon} />  



            </StyledTreeItem>   

     <StyledTreeItem nodeId="23.1" className={ path==='/allExpertReviews/pending' ? classes.itemSelected : classes.itemNotSelected}  labelText="Expert Reviews" onClick={()=> props.history.push('/allExpertReviews/pending')} labelIcon={StarIcon}  >
            <StyledSubItem 
            className={ path=== '/allExpertReviews/pending' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="25.1" labelText="Pending for Approval" onClick={()=> props.history.push('/allExpertReviews/pending')} labelIcon={FiberManualRecordIcon} /> 
            <StyledSubItem 
            className={ path=== '/allExpertReviews' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="25.2" labelText="All Expert reviews" onClick={()=> props.history.push('/allExpertReviews')} labelIcon={FiberManualRecordIcon} /> 
            <StyledSubItem 
            className={ path==='/addExpertReview' ? classes.itemSelected : classes.itemNotSelected} 
            nodeId="24" labelText="Add new" onClick={()=> props.history.push('/AddExpertReview')} labelIcon={FiberManualRecordIcon} />
                


          </StyledTreeItem>
          
          <StyledTreeItem 
          className={ path==='/deliveryPartner' ? classes.itemSelected : classes.itemNotSelected} 
          nodeId="28" labelText="Delivery partners" onClick={()=> props.history.push('/deliveryPartner')}  labelIcon={MarkunreadMailboxIcon} ></StyledTreeItem>      


          
          <StyledTreeItem nodeId="29" labelText={"Log out  "+store.get('log_in_user')} labelIcon={ExitToAppIcon} onClick={()=> setOpenDilaog(true)} ></StyledTreeItem>          
        </TreeView>         
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route path='/' exact component={ViewRes}/>
        <Route path='/addnewrestaurant' exact component={AddNewRestaurent} />
        <Route path='/allcoffeeShops' exact component={AllCoffeeshops} />
        <Route path='/allhawkers' exact component={AllHawkers} />
        <Route path='/addNewPromotions' exact component={AddNewPromotions} />

        <Route path='/hawfeaturesTags' exact component={FeaturesTags} />
        <Route path='/cfefeaturesTags' exact component={FeaturesTags} />
        <Route path='/resfeaturesTags' exact component={FeaturesTags} />

        <Route path='/resarea' exact component={Areas}/>
        <Route path='/hawarea' exact component={Areas}/>
        <Route path='/cfearea' exact component={Areas}/>

        <Route path='/rescuisines' exact component={Cuisine}/>
        <Route path='/hawcuisines' exact component={Cuisine}/>
        <Route path='/users' exact component={Users}/>    

         <Route path='/experts' exact component={Experts}/>  

              <Route path='/expertsPoints' exact component={ExpertsPoints}/>  
                 <Route path='/expertsPointsSettings' exact component={ExpertsPointsSettings}/>  

           <Route path='/expertsignup' exact component={ExpertSignUp}/>  


        <Route path='/allPromos/:id' exact component={PromotionPage}/>
        <Route path='/allPromos' exact component={AllPromo}/>
        <Route path='/promoTypes' exact component={PromoTypes}/>        
        <Route path='/restaurant' exact component={Restaurent}/>              
        <Route path='/resPromo' exact component={ResPromo}/>
        <Route path='/reviews' exact component={Review}/>
        <Route path='/allReviews' exact component={AllReviews}/> 
        <Route path='/allReviews/:id' exact component={ReviewPage}/>
        <Route path='/addNewReview' exact component={AddNewReview}/>       

          <Route path='/allExpertReviews' exact component={ExpertReviewPage}/> 
        <Route path='/allExpertReviews/:id' exact component={ExpertReviewPage}/>
        <Route path='/addExpertReview' exact component={AddExpertReview}/>     


        <Route path='/deliveryPartner' exact component={Delivery}/>         
        <Route path='/foodSpeciality' exact component={FoodSpeciality}/>
        <Route path='/city' exact component={City}/>
        <Route path='/categories' exact component={Category}/>
      </main>       
      </div>      
      
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Do you want to logout</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={logout} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    // fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" style={{fontSize: 16}} className={classes.labelIcon} />
          <ListItemText primary={labelText} style={{ color:'rgba(0, 0, 0, 0.54)'}} />          
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

function StyledSubItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" style={{fontSize: 10}} className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledSubItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default withRouter(MainPage);
