import React from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Typography, Card, CardMedia, IconButton, CardActions, CardActionArea,CardContent , Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import { url, produrl } from './constant'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';

class ResPromo extends React.Component{
    constructor(){
        super()
        this.state = {
            columns: [
                {
                    "title": "Promo title",
                    "field": "title"
                },
                {
                    "title": "Restaurant",
                    "field": "res_name",
                    "hidden": true
                },
                {
                    "title": "Offer",
                    "align": 'center',
                    "field": "price"
                },
                {
                    "title": "Offer type",
                    "align": 'center',
                    "field": "type"
                },
                {
                    "title": "Valid upto",
                    "align": 'center',
                    "field": "valid_upto"
                },
                {
                    "title": "Total",                    
                    "align": 'center',
                    "field": "total_no_of_coupons"
                },
                {
                    "title": "Remaing available",
                    "align": 'center',
                    render: rowData => <div>{rowData.total_no_of_coupons - rowData.used.length}</div> 

                },
                {
                    title: "Actions",
                    align: "center",
                    disableClick: true,
                    render: rowData => 
                    <div style={{display: 'flex', width: "100%", justifyContent: "space-evenly" }} >
                        <EditIcon color="primary" onClick={()=> this.props.history.push({pathname: '/allPromos/'+rowData["res_id"], state: {data: rowData, edit: true}}) } />
                        <DeleteIcon color="secondary" onClick={()=> this.setState({openDialog: true, deleteData: rowData})} />
                    </div>                
                },                
            ],
            data:[],
            openDialog: false,
            deleteData: {}
        }
    }
    componentDidMount(){
        this.getAllPromos()        
    }

    getAllPromos=()=>{
        fetch(url+"/getPromosSpecific", {
            method: "POST",
            body: JSON.stringify({"id": this.props.location.state.id })            
        })
        .then(res => res.json())
        .then((data)=>{
            this.setState({
                data: data['response']
            })
        })
    }

    deletePromo(oldData){        

        fetch(url+"/deletePromo",{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"id": oldData['_id']['$oid']})
        })
        .then((res)=> res.json())
        .then((data)=>{            
            if(data["status"]==="success"){
                alert("Deleted successfully")
                this.setState({
                    openDialog: false
                })
                this.getAllPromos()
            }
        })
    }

    render(){
        const { columns, data, deleteData, openDialog } = this.state
        return(
            <div style={{width: "100%"}}>            
                <MaterialTable 
                    columns={columns} 
                    data={data} 
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10
                    }}
                    onRowClick={ (event, rowData) => this.props.history.push({pathname: '/allPromos/'+rowData["res_id"], state: {data: rowData}})}
                    title={this.props.location.state.name}
                    components={{
                        Toolbar: props => (
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }}><MTableToolbar style={{ flex: 12 }} {...props} /></div>

                                <div style={{ padding: 4, alignSelf: 'center' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Button variant='outlined' onClick={()=>  this.props.history.push("/addNewPromotions")} style={{marginRight: 5}}>
                                            Add_New
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />
                <Dialog
                    open={openDialog}
                    onClose={()=> this.setState({openDialog: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Are you sure do you want to delete</DialogTitle>

                    <DialogActions>
                    <Button onClick={()=> this.setState({openDialog: false})} color="primary">
                        No
                    </Button>
                    <Button onClick={()=> this.deletePromo(deleteData)} color="secondary" autoFocus>
                        Yes
                    </Button>
                    </DialogActions>
                    </Dialog>
                </div>
        )
    }
}

export default withRouter(ResPromo)