import React from 'react'
import { Button, Typography, TextField, Snackbar,Card, CardActions,
 Dialog, 
    DialogActions, 
    DialogContent,  
    DialogTitle} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { produrl, url, formFields, formFieldsCntue, prefixImageUrl } from './constant'
import store from 'store'
import Alert from './Alert'

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';


//Image resize
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './img.css'
import moment from "moment";

class AddExpertReview extends React.Component{
    constructor(){
        super()
        this.state = {
            fetchRestaurantData: [],
            reviewData: {
             featured_image: '',
           gallery_images: [],
           status:false,
           addedby:store.get('log_in_user'),
           addedon:moment().format("DD-MMM-YYYY hh:mm a")

            },
             crop: {
                unit: '%',
                width: 50,
                aspect: 1 / 1,
            },
            openImageCrop: false,
            featured_image_upload: true,
            gallery_images_upload: false,
            reactCropOpen: false,
            openGalleryImageDialog: false,
            imageUploading: false,  


        }
    }

    addReview = (e) =>{
        e.preventDefault();
        fetch(url+'/addExpertReview',{
            method: 'POST',
            body: JSON.stringify(this.state.reviewData)
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    reviewData: {
                      featured_image: '',
           gallery_images: [],
           status:"new"
       }
                })            
                this.setState({      
                    setSnack:true,
                    message:'Added successfully',
                    msgstatus:"success"
                })     
                setTimeout(()=>{
                    this.props.history.push("/allMyExpertReviews")
                }, 300)                
            }
        })
    }

    handlePromoChange = (e) => {      
        this.setState({ 
            reviewData: {...this.state.reviewData, [e.target.name] : e.target.value}
        });
    }

    selectArea = (v)=>{
        this.setState({
            reviewData: {...this.state.reviewData, "res_id": v["res_id"], "res_name": v["res_name"] },            
        })
    }

    fetchRestaurant = e => {
        this.setState({
            fetchRestaurantData: []
        })
        fetch(url+'/fetchRestaurantAutoComplete',{
            method: "POST",
            body: JSON.stringify({"name": {"$regex": e.target.value, $options:'i'}})
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status'] == "okay"){                
                data['response'].map(item =>{
                    this.setState({                        
                        fetchRestaurantData:  [...this.state.fetchRestaurantData , {"res_id": item['_id']['$oid'], "res_name": item['name']}]
                    })
                })                
            }
        })
    }
    
    handleClose = e =>{
        this.setState({
          setSnack: null 
        })
    }

    uploadCropedImage = () =>{
        this.setState({
            imageUploading: true
        })
        let form_data = new FormData();      

        form_data.append('image', this.state.blob);                   
        
        fetch(produrl+"/getImage",{
            method: "POST",
            body: form_data
        })
        .then((res)=> res.json())
        .then((data)=>{        
            if(this.state.featured_image_upload === true){                
                this.setState({
                    openImageCrop: false,
                    featured_image_upload: false,
                    imageUploading: false,
                    reviewData: {...this.state.reviewData, "featured_image": data['data']}                
                })
            }else if(this.state.gallery_images_upload === true){
                this.setState({
                    openImageCrop: false,
                    gallery_images_upload: false,
                    imageUploading: false,
                    reviewData: {...this.state.reviewData, "gallery_images": [...this.state.reviewData.gallery_images, data['data']] }                
                })
            }
        })
    }

    handleImageChange = (e) =>{
        if(e){
            if(e.target.name == 'featured_image'){
                this.setState({
                    featured_image_upload: true,
                    gallery_images_upload: false,
                    crop: { ...this.state.crop, aspect: 1/1 }
                })
            } else {
                this.setState({
                    featured_image_upload: false,
                    gallery_images_upload: true,
                    crop: { ...this.state.crop, aspect: 1.5 / 1 }
                })
            }
            const reader = new FileReader();
            
            if(reader && e.target.files[0]){
                reader.addEventListener('load', () =>
                    this.setState({ image: reader.result, openImageCrop: true })
                );
                reader.readAsDataURL(e.target.files[0])
            }
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {            
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );          
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {    
              return;
            }
            blob.name = fileName;
            this.setState({
                blob: blob
            })
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
    }


    render(){
       
                const { fetchRestaurantData, image, crop, openImageCrop, data, imageUploading, loading} = this.state;      
        return(
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "90vh", alignItems: 'center' }} >
            <form onSubmit={(e)=> this.addReview(e, this.state.edit)} style={{width: "45%"}}>   
            <div style={{display: 'flex', justifyContent: "flex-start" , width: "100%" }}>
                <Typography variant='h5' >Add Expert Review</Typography>  
            </div>
            <Autocomplete
                style={{width: "100%", margin: "20px 0px"}}                            
                options={fetchRestaurantData} 
                id="area"
                onChange={(e, value) => this.selectArea(value)}
                getOptionLabel={option => option.res_name}                               
                renderInput={params => (
                <TextField
                    required
                    {...params}
                    variant="outlined"
                    label="Select restaurant"
                    placeholder="Select restaurant"
                    onChange={this.fetchRestaurant}
                />
                )}
            />
            {promoField.map((item, index) =>
                <TextField  
                    style={{marginBottom: 14}}
                    fullWidth
                    key={index}                              
                    label={item.label}                            
                    required
                    onChange={this.handlePromoChange}
                    multiline={item.multiline}
                    rows={item.noOfRows}
                    variant="outlined"
                    type={item.type}
                    name={item.name}
                    value={this.state.reviewData[item.name]}                            
                />
            )}       

               {                          <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 10}}>Featured image</Typography>
                                            { this.state.reviewData.featured_image == '' ? 
                                                <div>
                                                    <Button   
                                                        size="small" 
                                                        color="primary"
                                                        variant="outlined" 
                                                        onClick={()=> document.getElementById('featured_image').click()}
                                                    >
                                                        Select image
                                                    </Button>
                                                </div> :
                                                <Card style={{width: 280, boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.4)"}}>                                                                                            
                                                    <img src={prefixImageUrl+this.state.reviewData.featured_image} style={{width: 280, height: 300, objectFit: 'contain'}}/>
                                                    <CardActions>
                                                        <div style={{display: 'flex', flex: 1}}>                                
                                                            <Button  size="small" color="secondary" variant="outlined" onClick={()=> document.getElementById('featured_image').click()}>
                                                                Change
                                                            </Button>
                                                        </div>
                                                    </CardActions>
                                                </Card>                                                
                                            }
                                        </div>
                                    }

                { 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 10}}>Gallery images</Typography>
                                            <div >                                                                        
                                                <Button  size="small" color={this.state.invisible ? "secondary":"primary"} variant="outlined" onClick={()=> this.setState({ openGalleryImageDialog: true }) }>
                                                    { this.state.reviewData.gallery_images.length > 0 ? this.state.reviewData.gallery_images.length > 1 ? "View gallery images" : "View gallery image" : "Add gallery images"}
                                                </Button>
                                            </div>
                                        </div>
                }

                     <input
                                hidden
                                type="file"
                                style={{padding: 10, margin: 10,}}
                                // required                        
                                id="featured_image"
                                accept="image/png, image/jpeg" 
                                name="featured_image"
                                onChange={this.handleImageChange}
                            />
                            <input
                                hidden
                                type="file"
                                style={{padding: 10, margin: 10,}}                        
                                id="gallery_image"
                                accept="image/png, image/jpeg" 
                                name="gallery_image"
                                onChange={this.handleImageChange}
                            />

            <div style={{display: 'flex', justifyContent: "flex-end" , width: "100%" }}>
                <Button type='submit' variant="outlined" color="secondary" >
                    Add review
                </Button>     
            </div>            
            </form>

                 <Dialog maxWidth  open={openImageCrop} >
                    <DialogTitle>Resize image</DialogTitle>
                    <DialogContent style={{ minHeight: 600 }}>                     
                    <ReactCrop
                        src={image}
                        style={{ minHeight: 600, width: this.state.openGalleryImageDialog ? 850 : 600 }}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='secondary' onClick={this.uploadCropedImage} >
                            {imageUploading ? "Uploading" : "Save"}
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openImageCrop: false })} >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

 <Dialog maxWidth open={this.state.openGalleryImageDialog} onClose={()=> this.setState({ openGalleryImageDialog: false })} >
                    <DialogTitle>Gallery images * three images minimum required</DialogTitle>
                    <DialogContent style={{ minHeight: 400, width: 1075, display: 'flex', flexWrap: 'wrap' }}>
                        {
                            this.state.reviewData.gallery_images &&  this.state.reviewData.gallery_images.map((item, i)=>
                                <Card style={{width: 300, margin: "0px 10px", boxShadow: 'none' }} >
                                    <img src={prefixImageUrl+item} style={{ height: 170, width: 300,  objectFit: 'cover' }} />
                                    <CardActions>                                                                        
                                        <Button variant='outlined'  size="small" color="secondary" onClick={ ()=>this.deleteImag(i)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        }                    

                    <Button style={{ height: 170, width: 300, display: 'flex',  justifyContent: 'center', alignItems: 'center' }} onClick={()=> document.getElementById('gallery_image').click()} variant='outlined' >
                         <div> <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} > <AddIcon/> Add images </div> </div> 
                    </Button>
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openGalleryImageDialog: false })} >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>


            <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            </div>
        )
    }
}

const promoField = [
 
    {
        "label": "Rating",
        "name":"rating",
        "type": "text"        
    },
       {
        "label": "Title",
        "name":"title",
        "type": "text",
        "noOfRows": 1
    },
        {
        "label": "Video Url (Youtube / Instagram)",
        "name":"video",
        "type": "url",
        
    },
    {
        "label": "Summary",
        "name":"summary",
        "type": "text",
        "multiline": true,
        "noOfRows": 4        
    }
]

export default AddExpertReview;