import React from 'react'
import { Button, Typography, TextField, Snackbar } from '@material-ui/core';
import { url } from './constant'
import Autocomplete from '@material-ui/lab/Autocomplete';

import Alert from './Alert'

class ExpertsPointsSettings extends React.Component{
    constructor(){
        super()
        this.state = {
            fetchRestaurantData: [],
            expertSettings: {}
        }
    }

    saveSettings = (e) =>{
        e.preventDefault();
        fetch(url+'/saveExpertSettings',{
            method: 'POST',
            body: JSON.stringify(this.state.expertSettings)
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    expertSettings: {}
                })            
                this.setState({      
                    setSnack:true,
                    message:'Saved Successfully',
                    msgstatus:"success"
                })     
                setTimeout(()=>{
                    this.props.history.push("/expertsPointsSettings")
                }, 300)                
            }
        })
    }

    handlePromoChange = (e) => {      
        this.setState({ 
            expertSettings: {...this.state.expertSettings, [e.target.name] : e.target.value}
        });
    }

    selectArea = (v)=>{
        this.setState({
            expertSettings: {...this.state.expertSettings, "res_id": v["res_id"], "res_name": v["res_name"] },            
        })
    }

   componentDidMount(){
        this.getdata()
    }

    getdata=()=>{
        this.setState({
            loading: true
        })
        fetch(url+"/getExpertsSettings",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    expertSettings: data['response'][0],
                    loading: false
                })

                console.log(this.state.expertSettings);
            }        
        })
        .catch((er)=>{
            this.setState({                  
                loading: false
            })
        })
    }


    
    handleClose = e =>{
        this.setState({
          setSnack: null 
        })
    }

    render(){
        const { fetchRestaurantData } = this.state;
        return(
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "90vh", alignItems: 'center' }} >
            <form onSubmit={(e)=> this.saveSettings(e, this.state.edit)} style={{width: "45%"}}>   
            <div style={{display: 'flex', justifyContent: "flex-start" , width: "100%" }}>
                <Typography variant='h5' >Experts Points Settings</Typography>  
            </div>
          
            {promoField.map((item, index) =>
                <TextField  
                    style={{marginBottom: 14}}
                    fullWidth
                    key={index}                              
                    label={item.label}                            
                    required
                    onChange={this.handlePromoChange}
                    multiline={item.multiline}
                    rows={item.noOfRows}
                    variant="outlined"
                    type={item.type}
                    name={item.name}
                    value={this.state.expertSettings[item.name]}                            
                />
            )}           
            <div style={{display: 'flex', justifyContent: "flex-end" , width: "100%" }}>
                <Button type='submit' variant="outlined" color="secondary" >
                   Save
                </Button>     
            </div>            
            </form>
            <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            </div>
        )
    }
}

const promoField = [
    {
        "label": "Points per Review",
        "name":"pointsPerReview",
        "type": "number",
       
    }
]

export default ExpertsPointsSettings;