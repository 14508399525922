import React from 'react'
import { Button, Typography, Switch, Snackbar, FormControlLabel, CardMedia, CircularProgress,IconButton, CardActions, CardActionArea,CardContent , Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel, TextField, NativeSelect } from '@material-ui/core';
import { url, produrl, prefixImageUrl } from './constant'
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom'

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';

import Alert from './Alert'

class PromotionPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tableTitle: '',
            openDialog: false,
            openReportDialog: false,
            columns: [
                {
                    title: "Actions",
                    align: "center",
                    render: rowData => 
                        <div style={{display: 'flex', width: "100%", justifyContent: "space-evenly" }} >
                            <Button> <EditIcon style={{color: "rgba(0, 0, 0, 0.7)"}} onClick={()=> this.editPromo(rowData)} /> </Button>
                            {rowData.deleted ? <Button> <RefreshIcon style={{color: "rgba(0, 0, 0, 0.7)"}} onClick={()=> this.recoverDeleted(rowData)}/> </Button> : <Button> <DeleteIcon style={{color: "rgba(0, 0, 0, 0.7)"}} onClick={()=> this.deletePromo(rowData)}/> </Button> }
                        </div>
                },
                {
                    title: "Coupon type",
                    align: "center",
                    render: rowData => 
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={rowData.couponType === 'Main coupon' ? true : false } 
                                    onChange={()=>this.changeCouponType(rowData, rowData.couponType === 'Main coupon' ? 'Sub coupon' : 'Main coupon')} 
                                    name="checkedA"
                                />
                            }
                        />
                },
                {
                    "title": "Name",
                    "field": "title"
                },
                {
                    "title": "Received",
                    render: rowData => <div>{rowData.used.length}</div>,
                    "align": 'center' 
                },
                {
                    "title": "Consumed",
                    render: rowData => <div>{this.getConsumed(rowData.used)}</div>,
                    "align": 'center' 
                },
                {
                    "title": "Remaining available",
                    "field": "total_no_of_coupons",
                    "align": 'center' 
                },
                {
                    "title": "Status",
                    render: rowData =>  rowData.deleted ? <div style={{ color: 'red' }} >Deleted</div> : <div style={{ color: new Date().toISOString().split('T')[0] > rowData['valid_upto'] ? 'red' : "green" }} >{ new Date().toISOString().split('T')[0] > rowData['valid_upto'] ? "Expired": "Available"}</div>,
                    "align": 'center'
                },
                {
                    "title": "Report",
                    render: rowData => <Button variant="outlined" color="primary" disabled={ rowData.used.length > 0 ? false: true } onClick={()=> this.viewUsers(rowData)}  >Report</Button>,
                    "align": 'center'
                }                   
            ],
            data: [],
            edit: false,
            promoFormData: {},
            loading: true,
            promoTypes: [],
            fetchRestaurantData: [],
            reportTableColumn: [
                {
                    "title": "User name",
                    "field": "name"                    
                },
                {
                    "title": "User email",
                    "field": "email"
                },
                {
                    "title": "Received on",
                    "field": "receivedOn"
                },
                {
                    "title": "Consumed",                    
                    render: rowData => <div style={{ color: rowData['consumed'] ? 'green' : "red" }} >{rowData['consumed'] ? 'Consumed' : 'Not Consumed'}</div>,
                },
                {
                    "title": "Consumed on",
                    "field": "consumedOn"
                },
            ],
            reportTableData: [],
            openImageCrop: false,
            imageUploading: false,
            image: '',
            crop: {
                unit: '%',
                width: 100,
                aspect: 1 / 1,
            },
            couponType: ['Main coupon', 'Sub coupon']
        }
    }

    componentDidMount(){
        this.fetchPromo();
        this.getPromoType()
    }

    changeCouponType =(e, type)=> {
        console.log(e , type)
        let data = this.state.data;        
        let id = e["_id"]["$oid"]

        fetch(url+"/changeCouponType", {
            method:"POST",
            body: JSON.stringify({
                "id": id,
                "couponType": type
            })
        })
        .then(res => res.json())
        .then((resData)=>{            
            if(resData["status"]== "okay"){
                let index = data.findIndex((item) => item["_id"]["$oid"] == id )                
                let item = data[index]
                item.couponType = type
                console.log("Hello", item)
                data[index] = item
                this.setState({ data })
            }
        })
    }

    //Function used to the promotions type
    getPromoType=()=>{
        fetch(url+"/getPromoType",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    promoTypes: data['response']
                })                
            }        
        })
    }

    //fetch sepcific restaurant promotions
    fetchPromo = () => {
        this.setState({
            loading: true
        })
        fetch(url+'/getPromo',{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"id": this.props.match.params.id})
        })
        .then((res)=> res.json())
        .then((data)=>{
            if(data['status'] == "okay"){
                this.setState({
                    tableTitle: data['response'][0]['res_name'],
                    promoFormData: data['response'][0],
                    data: data['response'],                    
                })
                setTimeout(()=>{
                    this.setState({
                        loading: false
                    })
                }, 400)
            }
        })
    }

    getConsumed = (data) => {
        let temp = data.filter((item)=> item['consumed'] == true )
        return temp.length
    }
    openDialog =(key)=>{
        this.setState({
            [key]: !this.state[key]
        })
    }

    handlePromoChange = (e) => {      
        this.setState({ 
            promoFormData: {...this.state.promoFormData, [e.target.id] : e.target.value}
        });
    }

    addNewPromo = (e, edit) =>{
        e.preventDefault();                  
        let id = this.state.promoFormData._id['$oid']
        let data = this.state.promoFormData
        delete data._id 

        fetch(url+'/editPromo',{
            method: 'POST',
            body: JSON.stringify({
                "id": id,
                "data": data
            })
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({      
                    setSnack:true,
                    message:'Promotion edited successfully',
                    msgstatus:"success"                                
                })
                setTimeout(()=>{
                    this.props.history.push("/allPromos")
                }, 3000)
            }
        })
        .catch(err =>{
            this.setState({      
                setSnack:true,
                message:'Something went wrong',
                msgstatus:"error"                                
            })
        })        
    }

    handleImageChange = (e) =>{

        const reader = new FileReader();
        if(reader){
            reader.addEventListener('load', () =>
                this.setState({ image: reader.result, openImageCrop: true })
            );
            reader.readAsDataURL(e.target.files[0]);
        }        
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {            
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );          
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              return;
            }
            blob.name = fileName;
            this.setState({
                blob: blob
            })
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
    }

    uploadCropedImage = () =>{
        this.setState({
            imageUploading: true
        })
        let form_data = new FormData();      

        form_data.append('image', this.state.blob);                   
        
        fetch(produrl+"/getImage",{
            method: "POST",
            body: form_data
        })
        .then((res)=> res.json())
        .then((data)=>{        
            this.setState({
                openImageCrop: false,
                imageUploading: false,
                buttonDisable: false,                
                promoFormData: {...this.state.promoFormData, "promoImage": data['data']},            
            })
        })
    }

    viewUsers = (data) =>{
        this.setState({
            users: data.used,
            promoReportTitle: data.title
        })
        this.openDialog('openReportDialog')
    }

    deletePromo = (key) =>{
        if(key['used'].length == 0){            
            fetch(url+'/deletePromo', {
                method: "POST",
                body: JSON.stringify({id: key['_id']['$oid']})            
            })
            .then(res => res.json())
            .then((data)=>{
                if(data['status']=='okay'){
                    if(this.state.data.length > 1){
                        // this.state.data = this.state.data.splice(this.state.data.indexOf(key), 1)
                        // this.setState({ data })
                        this.fetchPromo()
                    } else if(this.state.data.length == 1){
                        this.props.history.push('/allPromos')
                    }
                }
            })
        }else if(key['used'].length > 0){            
            fetch(url+'/updateDeletedStatus', {
                method: "POST",
                body: JSON.stringify({id: key['_id']['$oid']})            
            })
            .then(res => res.json())
            .then((data)=>{
                if(data['status']=='okay'){
                    this.fetchPromo()
                    this.setState({      
                        setSnack:true,
                        message:'Deleted!',
                        msgstatus:"info"                                
                    })                
                }
            })
        }
    }

    recoverDeleted = (key) =>{        
        fetch(url+'/restoreDeletedPromo', {
            method: "POST",
            body: JSON.stringify({id: key['_id']['$oid']})            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.fetchPromo() 
                this.setState({      
                    setSnack:true,
                    message:'Promotion restored successfully',
                    msgstatus:"success"                                
                })                
            }
        })
    } 

    editPromo = (key) =>{    
        if(key['couponType']){
            key['deleted']=false
            this.setState({
                promoFormData: key,
                edit: true
            })        
            this.openDialog("isDialogOpen")
        } else {
            key['couponType'] = 'Main coupon'
            key['deleted']=false
            this.setState({
                promoFormData: key,
                edit: true
            })        
            this.openDialog("isDialogOpen")
        }
    }
    
    handleSelectChange = (e) =>{
        this.setState({ 
            promoFormData: {...this.state.promoFormData, "type" : e.target.value}
        });
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    selectCouponType = (data) => {
        this.setState({ 
            promoFormData: {...this.state.promoFormData, "couponType" : data}
        });
    }

    render(){                
        const { couponType, buttonDisable, promoTypes, columns, data, openImageCrop, crop, imageUploading, image, userColoumn, promoReportTitle, users } = this.state
        return(
            <div  style={{width: "100%"}} >                            
                <div  style={{width: "100%"}} >                      
                    {
                        this.state.edit ?  <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "100%", alignItems: 'center',  margin: "20px 0px"  }} >
                        <form onSubmit={(e)=> this.addNewPromo(e, this.state.edit)} style={{width: "45%"}}>             
                            <Typography variant='h5' align="center" >Edit promotions</Typography>                                            
                            <TextField
                                style={{margin: '15px 0px'}}
                                disabled={true}
                                type="text"
                                value={this.state.promoFormData.res_name}
                                variant='outlined'
                                fullWidth
                            />
                            <Autocomplete
                                style={{width: "100%", margin: "20px 0px"}}                            
                                options={promoTypes} 
                                id='type'
                                onChange={(e, value) => this.selectPromoType(value)}
                                getOptionLabel={option => option.fName}        
                                defaultValue={{fName: this.state.promoFormData.type}}                       
                                renderInput={params => (
                                    <TextField
                                        required
                                        {...params}
                                        variant="outlined"
                                        label="Promotion type"
                                        placeholder="Promotion type"                        
                                    />
                                )}
                            />
                            <Autocomplete
                                style={{width: "100%", margin: "20px 0px"}}                            
                                options={couponType} 
                                defaultValue={this.state.promoFormData.couponType}
                                id='type'
                                onChange={(e, value) => this.selectCouponType(value)}
                                getOptionLabel={option => option}                               
                                renderInput={params => (
                                    <TextField
                                        required
                                        {...params}
                                        variant="outlined"
                                        label="Coupon type"                            
                                    />
                                )}
                            />                                                    
                            {promoField.map(item =>
                                <TextField
                                    style={{margin: '10px 0px'}}
                                    autoFocus
                                    required
                                    id={item.name}
                                    label={item.label}
                                    type={item.type}
                                    disabled={item.disabled}
                                    value={this.state.promoFormData[item.name]}
                                    variant='outlined'
                                    fullWidth                    
                                    placeholder={item.placeholderLabel}
                                    InputLabelProps={{
                                        shrink: item.shrink
                                    }}
                                    onChange={this.handlePromoChange}                 
                                />
                            )}

                            <div >                
                                <Typography style={{margin: "10px 0px"}}>Promotion Image</Typography>
                                <div style={{display: "flex", flexDirection: 'column' }} >
                                    <img src={this.state.promoFormData.promoImage.length > 50 ? prefixImageUrl+this.state.promoFormData.promoImage.split(':')[2].split("/")[2]+'.jpg' : prefixImageUrl+this.state.promoFormData.promoImage} style={{height: 192, width: 192 }} />
                                    <input
                                        hidden
                                        type="file"
                                        style={{padding: 10}}                                    
                                        id="promoImage"                         
                                        accept="image/png, image/jpeg" 
                                        name="promoImage"                                        
                                        onChange={this.handleImageChange}                
                                    />   
                                    <div style={{display: 'flex', flex: 1, marginTop: 10}}>                                
                                        <Button color="primary" variant="outlined" onClick={()=> document.getElementById('promoImage').click() }>
                                            Change
                                        </Button>
                                    </div>
                                </div>             
                            </div>
                            <div style={{display: 'flex', justifyContent: "flex-end" , width: "100%" }}>
                                <Button type='submit' variant="outlined" color="secondary" disabled={buttonDisable} >
                                    Done
                                </Button>     
                            </div>            
                            </form>
                        </div> :                         
                        <div style={{width: "100%",  margin: "20px 0px" }} >
                            <MaterialTable 
                                columns={columns}                                      
                                data={data} 
                                isLoading={this.state.loading}
                                options={{
                                    pageSizeOptions: [10],
                                    pageSize: 10                            
                                }}                                                
                                title={this.state.tableTitle + " " + "promotions"}
                            /> 

                            <Dialog fullWidth maxWidth open={this.state.openReportDialog} onClose={()=> this.viewUsers('openReportDialog') } >
                                <DialogContent style={{padding: 0}} >
                                    <MaterialTable
                                        title={promoReportTitle}
                                        data={users}
                                        columns={this.state.reportTableColumn}
                                        options={{
                                            exportButton: true,
                                            exportFileName: ''
                                        }}                            
                                    />
                                </DialogContent>
                            </Dialog>
                        </div>
                    }
                </div>

                <Dialog maxWidth  open={openImageCrop} >
                    <DialogTitle>Resize image</DialogTitle>
                    <DialogContent style={{ minHeight: 600, width: 600 }}>                     
                    <ReactCrop
                        src={image}
                        style={{ minHeight: 600, width: 600 }}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='secondary' onClick={this.uploadCropedImage} >
                            {imageUploading ? "Uploading" : "Save"}
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openImageCrop: false })} >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default withRouter(PromotionPage)


const promoField = [
    {
        "label": "Promo title",
        "name":"title",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false
    },
    {
        "label": "Offer",
        "name":"price",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "placeholderLabel": "Eg. Free or 10% offer"
    },
    {
        "label": "Total number of promotions",
        "name":"total_no_of_coupons",
        "type": "number",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "disabled": "this.state.promoFormData.used.length > 0"
    },
    {
        "label": "Available from date",
        "name":"availFrom",
        "type": "date",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "shrink": true
    },
    {
        "label": "Expiry date",
        "name":"valid_upto",
        "type": "date",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "shrink": true
    },
]