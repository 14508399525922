import React from 'react'
import  { url } from './constant'
import { withRouter } from "react-router-dom"
import { Typography, TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import ClearIcon from '@material-ui/icons/Clear';

import MaterialTable, { MTableToolbar } from 'material-table';

class AllReviews extends React.Component{
    constructor(){
        super();
        this.state = {            
            columns: [                
                {
                    "title": "Restaurant ",
                    "field": "name"
                },
                {
                    "title": "Number of reviews",
                    "align": "center",
                    "field": "noOfReviews"
                }
            ],           
            data:[],
            limit: 10,
            dataFetch: [],
            skip: 0,
            loading: false,
        }
    }

    componentDidMount(){    
        this.handleSearchSubmit(10, 0, false)
    }

    handleSearchSubmit = async (limit, skip, frmOnClr) =>{
        this.setState({
            loading: true
        })        
        if(frmOnClr){         
            this.setState({
                data: []              
            })       
        }               
        await fetch(url+'/getrestaurants1', {
            method: "POST",
            body: JSON.stringify({"data": {}, "skip": skip, "limit": limit})
        })
        .then(res =>res.json())
        .then((data)=>{                        
            this.setState({
                resLength: data['length'],                
            })
            var restaurantDetails = data['response']
            restaurantDetails.map((item, index)=>{
                fetch(url+"/getNoOfReviews", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({"id":  item['_id']['$oid'] })
                })
                .then(res => res.json())
                .then((data)=>{      
                    if(data['status']=='okay'){
                        let promoData = data['response']                    
                        
                        if(promoData['length'] > 0){
                            let restaurantId =  promoData['res_id']
                            let numberOfPromos = promoData['length']
                            
                            if(item['_id']['$oid'] == restaurantId){
                                restaurantDetails[index]['noOfReviews'] = numberOfPromos
                            }                                                             
                        } else if(promoData['length'] == 0){
                            restaurantDetails[index]['noOfReviews'] = 0                            
                        }
                    }
                })
            })        

            setTimeout(()=> 
                this.setState({
                    data: this.state.data.concat(restaurantDetails),
                    loading: false
                }), 
                3000 
            )
        })
        .catch((err) =>{
            this.setState({
                loading: false
            })
        })
    }

    fetchRestaurant = e => {
        if(e){
            this.setState({
                dataFetch: []
            })
            fetch(url+'/fetchRestaurantAutoComplete',{
                method: "POST",
                body: JSON.stringify({"name": {"$regex": e.target.value, $options:'i'}})
            })
            .then(res=> res.json())
            .then((data)=>{
                if(data['status'] == "okay"){    
                    this.setState({
                        dataFetch: data['response']
                    })                    
                }
            })
        }
    }

    acceptReview = review =>{ 
        fetch(url+"/acceptReview", {
            method: "POST",
            body: JSON.stringify({ "id":  review["_id"]["$oid"], 'res_id': review['res_id']})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status'] == "okay"){
                this.getReviews()
            } 
        })
    }

    handleOnSelect = (v)=>{
        if(v){
            const data = [v]            
            var restaurantDetails = data;
            restaurantDetails.map((item, index)=>{
                fetch(url+"/getNoOfReviews", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({"id":  item['_id']['$oid'] })
                })
                .then(res => res.json())
                .then((data)=>{      
                    if(data['status']=='okay'){
                        let promoData = data['response']                    
                        
                        if(promoData['length'] > 0){
                            let restaurantId =  promoData['res_id']
                            let numberOfPromos = promoData['length']
                            
                            if(item['_id']['$oid'] == restaurantId){
                                restaurantDetails[index]['noOfReviews'] = numberOfPromos
                            }                                                             
                        } else if(promoData['length'] == 0){
                            restaurantDetails[index]['noOfReviews'] = 0                            
                        }
                    }
                })
            })        
            this.setState({
                data: [],
            })
            setTimeout(()=> 
                this.setState({                
                    data: this.state.data.concat(restaurantDetails),
                    loading: false
                }),
                1000 
            )
        }
    }

    render(){
        const { loading, data ,columns} = this.state
        return(
            <div style={{width: "100%"}}>  
                <Typography variant='h5' component='h5' gutterBottom>All Reviews</Typography>          
                <Autocomplete
                    style={{width: "100%", margin: "20px 0px"}}                            
                    options={this.state.dataFetch}
                    closeIcon={ <ClearIcon onClick={()=> data.length < 20 && this.handleSearchSubmit(10, 0, true)} />}
                    id="area"
                    onChange={(e, value) => this.handleOnSelect(value)}
                    getOptionLabel={option => option.name}
                    value=''                    
                    renderInput={params => (
                    <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Select restaurant"
                        placeholder="Select restaurant"
                        onChange={this.fetchRestaurant}
                    />
                    )}
                />
                <MaterialTable 
                    columns={columns} 
                    data={data} 
                    options={{
                        toolbar: false,
                        pageSizeOptions: [5],
                        pageSize: 5
                    }}                    
                    isLoading={this.state.loading}      
                    onChangePage={(page)=> (10 * page) == data.length ? this.handleSearchSubmit(10, (10 * page) + 10, false) : '' }
                    onRowClick={ (event, rowData) => rowData['noOfReviews'] > 0 ? this.props.history.push({pathname: '/allReviews/'+rowData["_id"]['$oid']}) : alert("The selected restaurant has no reviews") }
                    title="All promos"                    
                />
            </div>
        )
    }
}

export default withRouter(AllReviews);
