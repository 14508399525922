import React from 'react'
import  { url } from './constant'
import { withRouter } from "react-router-dom"
import { Card, CardActions, CardContent, CircularProgress, Typography, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class Review extends React.Component{
    constructor(){
        super();
        this.state = {
            reviews: [],
            loading: false
        }
    }

    componentDidMount(){
        this.getReviews()
    }

    getReviews = () =>{
        this.setState({
            loading: true
        })
        fetch(url+"/getReviews", {
            method: "POST",
            body: JSON.stringify({"id": this.props.location.state.data})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status'] == "success"){
                this.setState({
                    reviews: data['response'],
                    loading: false
                })
            }            
        })
        .catch(err =>{
            this.setState({
                loading: false
            })
        })
    }

    acceptReview = review =>{ 
        fetch(url+"/acceptReview", {
            method: "POST",
            body: JSON.stringify({ "id":  review["_id"]["$oid"]})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status'] == "success"){
                this.getReviews()
            } 
        })
    }

    render(){
        const {reviews, loading} = this.state
        return(
            <div style={{width: "100%"}}>                
                {loading ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100vh" }}><CircularProgress/></div> : reviews.length > 0 ? 
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
                    <Typography gutterBottom variant='h5' >Reviews</Typography>
                {reviews.map(item => <Card style={{width: 670, maxHeight: 230, margin: "20px 10px 10px 0px", boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.4)"}}>
                    <CardContent>
                        <div style={{width: "100%", display: "flex", marginBottom: 13}}>
                            <Typography style={{flex: 1}} >{item.userName}</Typography>
                            <Typography>Rating {" "}{item.rating}</Typography>                
                        </div>
                        <Typography variant='caption' >Summary</Typography>
                        <Typography>{item.summary}</Typography>
                    </CardContent>
                    <CardActions style={{display: 'flex', width: "100%", justifyContent: "flex-end"}}>
                        {item.accepted ? <div style={{display: 'flex'}}><CheckCircleIcon style={{color: "green", marginRight: 6 }} /> <Typography style={{marginRight: "18px"}}>Approved</Typography></div>: <Button size="small" color="secondary" style={{marginRight: "18px"}} onClick={()=>this.acceptReview(item)} variant="outlined" >Approve</Button> }
                    </CardActions>
                </Card> )} </div> : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100vh" }}> <Typography>There is no reviews</Typography> </div>}
            </div>
        )
    }
}

export default withRouter(Review);