import React from 'react'
import { Button, Typography, Snackbar, Card,CardContent , Dialog, DialogContent } from '@material-ui/core';
import { url } from './constant'
import { withRouter } from 'react-router-dom'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MaterialTable from 'material-table';
import Alert from './Alert'

class ReviewPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tableTitle: '',
            openDialog: false,
            openReportDialog: false,
            columns: [                
                {
                    "title": "User name",
                    "field": "userName"                    
                },
                {
                    "title": "User email",
                    "field": "userEmail"
                },                
                {
                    "title": "Rating",
                    "field": "rating",
                    "align": 'center'
                },
                {
                    "title": "Review",
                    "field": "summary",
                    "align": 'center'
                },
                {
                    "title": "Status",
                    render: rowData=> <div style={{ color: rowData['accepted'] ? 'green' : "red" }} >{ rowData['accepted'] ? "Accepted": "Not accepted"}</div>,
                    "align": 'center'
                },    
                {
                    title: "Actions",
                    align: "center",
                    render: rowData => 
                        <div style={{display: 'flex', width: "100%", justifyContent: "space-evenly" }} >
                            {rowData['accepted'] ? <CheckCircleIcon style={{color: "green", marginRight: 6 }} /> : <Button onClick={()=> this.acceptReview(rowData)}  variant='outlined' color='primary' >Approve</Button>                            }
                        </div>
                },                             
            ],
            data: [],
            loading: true,
            openReviewDialog: false
        }
    }

    acceptReview = review =>{ 
        fetch(url+"/acceptReview", {
            method: "POST",
            body: JSON.stringify({ "id":  review["_id"]["$oid"], 'res_id': review['res_id']})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status'] == "okay"){
                this.fetchReview()
                this.setState({      
                    setSnack:true,
                    message:'Review accepted',
                    msgstatus:"success"
                }) 
            } 
        })
    }

    componentDidMount(){
        this.fetchReview();
    }    

    //fetch sepcific restaurant reviews
    fetchReview = () => {
        this.setState({
            loading: true
        })
        fetch(url+'/getReviews',{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"id": this.props.match.params.id})
        })
        .then((res)=> res.json())
        .then((data)=>{
            if(data['status'] == "okay"){
                this.setState({
                    tableTitle: data['response'][0]['res_name'],                
                    data: data['response'],
                    loading: false
                })
            }
        })
    }

    openDialog =(key)=>{
        this.setState({
            [key]: !this.state[key]
        })
    }

    handlePromoChange = (e) => {
        this.setState({ 
            promoFormData: {...this.state.promoFormData, [e.target.id] : e.target.value}
        });
    }

    viewReview = (data) =>{
        this.setState({
            reviewData: data         
        })
        setTimeout(()=> 
            this.openDialog('openReviewDialog'), 
            300 
        )        
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    deleteReview =(data)=>{
        
        fetch(url+'/deleteReview',{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"id": data['_id']['$oid']})
        })
        .then((res)=> res.json())
        .then((data)=>{
            if(data['status'] == "okay"){                
                this.setState({      
                    setSnack:true,
                    message:'Deleted successfully',
                    msgstatus:"success"
                })  
                this.fetchReview();
            }
        })
    }

    render(){                
        const { columns, data } = this.state
        return(            
            <div style={{width: "100%",  margin: "20px 0px" }} >
                <MaterialTable 
                    columns={columns}                                      
                    data={data} 
                    isLoading={this.state.loading}
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10,
                        actionsColumnIndex: -1                            
                    }}             
                    editable={{
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {                    
                                    this.deleteReview(oldData)
                                    resolve();
                                }, 100);
                            })
                    }}                                   
                    title={this.state.tableTitle + " " + "reviews"}
                    onRowClick={ (event, rowData) => this.viewReview(rowData)}
                /> 


                <Dialog fullWidth open={this.state.openReviewDialog} onClose={()=> this.openDialog('openReviewDialog') } >
                    <DialogContent style={{padding: 0}} >
                        <Card>
                        {this.state.reviewData && <CardContent>
                            <div style={{display: "flex", marginBottom: 13}}>
                                <Typography style={{flex: 1}} >{this.state.reviewData['userName']}</Typography>
                                <Typography>Rating {" "}{this.state.reviewData['rating']}</Typography>                
                            </div>
                            <Typography variant='caption' >Summary</Typography>
                            <Typography>{this.state.reviewData['summary']}</Typography>
                        </CardContent>}
                        </Card>
                    </DialogContent>
                </Dialog>

                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>       
            </div>
        )
    }
}

export default withRouter(ReviewPage)

