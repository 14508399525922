import React from 'react'
import {Button, Typography, TextField, Snackbar } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import store from 'store'
import logo from "../logo512.svg";
import { url } from './constant'
import Alert from './Alert'

class Login extends React.Component{
    constructor(){
        super();
        this.state = {}
    }

    handleSubmit = (e) => {
        e.preventDefault();
        fetch(url+"/login",{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(this.state)
        })
        .then((res)=> res.json())
        .then((data)=>{     
            console.log(data,"Res")       
            if(data['res']=="allow_user"){    
                store.set('log_in', true)
                console.log(this.state,"State")

                store.set('log_in_user', this.state.userName)
                if(data['usertype']=="admin"){
                 store.set('log_in_userAdmin', true)
                }
                else
                {
                    store.set('log_in_userAdmin', false)
                }
                window.location.reload()
            } else if(data['res']=="not_allow_user"){
               // alert('Invalid password')
                         if(data['status']=='okay'){                
                this.setState({      
                    setSnack:true,
                    message:'Invalid Username / Password',
                    msgstatus:"error",                    
                })
        
            }

            } else if(data['res']=="invalid_username"){
                        this.setState({      
                    setSnack:true,
                    message:'Invalid Username / Password',
                    msgstatus:"error",                    
                })
           
            }           
        })
        .catch((err)=>{
            alert("Something went wrong")
        })
    }

    handleExpertSignUp = (e) =>{
      this.props.history.push("/expertsignup")
       
                window.location.reload()
    }

    handleChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render(){
        const { userName, pwd } = this.state;
        return(
                <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "100%", alignItems: 'center' }} >

            <form onSubmit={this.handleSubmit} style={{display: 'flex', flexDirection: 'column', height: '90vh', justifyContent: 'center', alignItems: 'center'}}>
                <img src={logo} style={{width: 150}} />
                <TextField type="text" size='small' label="User name" style={{margin: "10px 0px", width: 260}} variant='outlined' name="userName" value={userName} required onChange={this.handleChange}/>
                <TextField type="password" size='small' label="Password" style={{margin: "10px 0px", width: 260}} variant='outlined' required name="pwd" value={pwd} onChange={this.handleChange}/>
                <Button style={{ width: 260, marginTop: 15 }} type="submit" variant="contained" color='secondary' >Log in</Button>
                <Button style={{ width: 260, marginTop: 15 }} type="button" variant="outlined" color='primary' onClick={this.handleExpertSignUp} >Expert & Affiliate Sign Up</Button>
           
 </form>
          <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            </div>

        )
    }
}

export default withRouter(Login)