import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store from 'store'
import Login from './Components/Login';
import MainPage from './Components/MainPage'
import ExpertDashboard from './Components/ExpertDashboard'
import ExpertSignUp from './Components/ExpertSignUp'
ReactDOM.render(
  <React.StrictMode>
  <Router>    

       <Route path="/expertsignup" > 
      <ExpertSignUp/>
    </Route>
    
    <Route path="/" > 
      {store.get("log_in") ? 
        store.get("log_in_userAdmin") ?
          <MainPage/> 
          :
          <ExpertDashboard/>
       : <Login/> }
    </Route>



  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();