import React from 'react'
import { Button, Typography, TextField, Snackbar } from '@material-ui/core';
import { url } from './constant'
import Autocomplete from '@material-ui/lab/Autocomplete';

import Alert from './Alert'

class AllNewReview extends React.Component{
    constructor(){
        super()
        this.state = {
            fetchRestaurantData: [],
            reviewData: {}
        }
    }

    addReview = (e) =>{
        e.preventDefault();
        fetch(url+'/addReview',{
            method: 'POST',
            body: JSON.stringify(this.state.reviewData)
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    reviewData: {}
                })            
                this.setState({      
                    setSnack:true,
                    message:'Added successfully',
                    msgstatus:"success"
                })     
                setTimeout(()=>{
                    this.props.history.push("/allReviews")
                }, 300)                
            }
        })
    }

    handlePromoChange = (e) => {      
        this.setState({ 
            reviewData: {...this.state.reviewData, [e.target.name] : e.target.value}
        });
    }

    selectArea = (v)=>{
        this.setState({
            reviewData: {...this.state.reviewData, "res_id": v["res_id"], "res_name": v["res_name"] },            
        })
    }

    fetchRestaurant = e => {
        this.setState({
            fetchRestaurantData: []
        })
        fetch(url+'/fetchRestaurantAutoComplete',{
            method: "POST",
            body: JSON.stringify({"name": {"$regex": e.target.value, $options:'i'}})
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status'] == "okay"){                
                data['response'].map(item =>{
                    this.setState({                        
                        fetchRestaurantData:  [...this.state.fetchRestaurantData , {"res_id": item['_id']['$oid'], "res_name": item['name']}]
                    })
                })                
            }
        })
    }
    
    handleClose = e =>{
        this.setState({
          setSnack: null 
        })
    }

    render(){
        const { fetchRestaurantData } = this.state;
        return(
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "90vh", alignItems: 'center' }} >
            <form onSubmit={(e)=> this.addReview(e, this.state.edit)} style={{width: "45%"}}>   
            <div style={{display: 'flex', justifyContent: "flex-start" , width: "100%" }}>
                <Typography variant='h5' >Add Review</Typography>  
            </div>
            <Autocomplete
                style={{width: "100%", margin: "20px 0px"}}                            
                options={fetchRestaurantData} 
                id="area"
                onChange={(e, value) => this.selectArea(value)}
                getOptionLabel={option => option.res_name}                               
                renderInput={params => (
                <TextField
                    required
                    {...params}
                    variant="outlined"
                    label="Select restaurant"
                    placeholder="Select restaurant"
                    onChange={this.fetchRestaurant}
                />
                )}
            />
            {promoField.map((item, index) =>
                <TextField  
                    style={{marginBottom: 14}}
                    fullWidth
                    key={index}                              
                    label={item.label}                            
                    required
                    onChange={this.handlePromoChange}
                    multiline={item.multiline}
                    rows={item.noOfRows}
                    variant="outlined"
                    type={item.type}
                    name={item.name}
                    value={this.state.reviewData[item.name]}                            
                />
            )}           
            <div style={{display: 'flex', justifyContent: "flex-end" , width: "100%" }}>
                <Button type='submit' variant="outlined" color="secondary" >
                    Add review
                </Button>     
            </div>            
            </form>
            <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            </div>
        )
    }
}

const promoField = [
    {
        "label": "Name",
        "name":"userName",
        "type": "text",
        "noOfRows": 1
    },
    {
        "label": "email",
        "name":"userEmail",
        "type": "text",        
    },
    {
        "label": "Rating",
        "name":"rating",
        "type": "text"        
    },
    {
        "label": "Summary",
        "name":"summary",
        "type": "text",
        "multiline": true,
        "noOfRows": 4        
    }
]

export default AllNewReview;