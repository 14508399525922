import React, { useState } from 'react'
import { 
    Button, 
    Snackbar, 
    Card, 
    CardActions, 
    Dialog, 
    DialogActions, 
    DialogContent,  
    DialogTitle, 
    TextField,
    Typography ,
    Chip,
    CircularProgress,
    Switch
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom'
import { produrl, url, formFields, formFieldsCntue, prefixImageUrl } from './constant'
import loadsh from 'loadsh'
import MUIRichTextEditor from "mui-rte";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//SnackBar Class
import Alert from './Alert'

//Image resize
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './img.css'

class AddNewRestaurent extends React.Component {
    constructor(){
        super();
        this.addCuisine = this.addCuisine.bind(this);
        this.state = {
            showcuisine:false,   
            showarea:false,
            showcity:false,
            deliveryPartners: [],
            image: '',
            openSelectHours: false,
            data: {
                address: '',
                area: '',
                category: 'Restaurant',
                city:'Chennai',
                cuisines: [],

                sf: [],
                delivery: [],
                description: null,
                short_desceription: "",
                email: '',
                fb_url: '',                
                featured_image: '',
                featuresTags: [],
                gallery_images: [],
                insta_id: "",
                lattitude: "",
                longitude: "",
                name: "",
                operatingTime: "",
                phone: "",
                rating: 0,
                routeField: '',
                secretKey: '1234',
                web_url: '',
                youtube_url: '',
                wa_number: "",
                priceRange: 'Affordable',
                isDisabled: true,
                featured: false ,
                           
            },
            activeStep: 0,
            fromDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            timings: [
                {"Monday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": true}},
                {"Tuesday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": true}},
                {"Wednesday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '',"fromTimeError": false, "toTimeError": false, "selected": true}},
                {"Thursday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": true}},
                {"Friday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": true}},
                {"Saturday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": true}},
                {"Sunday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": true}}
            ],
            applyForAllTimingsButton: true,
            youtubeUrlView: '',
            area: [],
            deliveryPartner: [],
            cuisinesMenu: [],
               cuisinesMenuTitles: [],   
            ft: [],
            crop: {
                unit: '%',
                width: 50,
                aspect: 1 / 1,
            },
            openImageCrop: false,
            featured_image_upload: true,
            gallery_images_upload: false,
            reactCropOpen: false,
            openGalleryImageDialog: false,
            imageUploading: false,            
            formTimeMenu: [
                '12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00AM", "09:30AM", '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM' 
            ],
            toTimeMenu: [
                '12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00AM", "09:30AM", '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM' 
            ],
            fromDayValue: '',
            toDayValue: '',
            fromTime: '',
            toTime: '',
            invisible: false,
            loading: false,
            validateOkay: false,
            changeToMultipleTimings: false,
            priceRanges: ['Affordable', 'Mid range', 'High end'],
            categories: [],
            cities: [],
            sflist: []
        }
        
    }

    componentDidMount(){
        fetch(url+"/getArea", {
            method: "GET"
        })
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                area: data['response']
            })
        })

        fetch(url+"/getFt", {
            method: "GET"
        })
        .then(res=>res.json())
          .then((data)=>{
         
                var ft = []
                data['response'].map(item =>{
                    ft.push(item.ft)
                })
                this.setState({ ft })
              console.log(this.state.ft,"loadFT")
        })
        fetch(url+"/getCuisine", {
            method: "GET"
        })
        .then(res=>res.json())
          .then((data)=>{
            this.setState({
                cuisinesMenu: data['response']
            })

                 this.state.cuisinesMenu.map((item)=>{
                        delete item["_id"]
                        this.setState({
                           ['cuisinesMenuTitles']: [...this.state['cuisinesMenuTitles'], item.title]
                        })
                    })

      console.log(this.state.cuisinesMenuTitles,"loadcuisines")
        })

        fetch(url+"/getDeliveryPartners",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    deliveryPartners: data['response']
                })
            }        
        })

        fetch(url+"/getTemp?collection=categories",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    categories: data['response']
                })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

        fetch(url+"/getTemp?collection=city",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    cities: data['response']
                })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

        fetch(url+"/getTemp?collection=specialFoods",{
            method: "GET"            
        })
        .then(res => res.json())
     .then((data)=>{
            if(data['status']=='okay'){
                var sflist = []
                data['response'].map(item =>{
                    sflist.push(item.sf)
                })
                this.setState({ sflist })
            }

            console.log(this.state.sflist,"SFoodLoad")
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    handleChange = (e) => {                            
        if(e.target.name=='youtube_url'){
            let temp = e.target.value.split("=")     
            this.setState({ 
                data: {...this.state.data, ['youtube_url'] : temp[1]}
            });

            setTimeout(()=>{
                console.log(this.state.data.youtube_url)
            }, 500)
        }
        else if(e.target.name=='fromTime'){   
            let meridiem = e.target.value.split(':')[0] > 12 ? "PM":"AM"
            this.setState({
                fromTime: e.target.value,
                fromTimeStore: e.target.value + " " + meridiem
            })            
        }
        else if(e.target.name=='toTime'){
            let meridiem = e.target.value.split(':')[0] > 12 ? "PM":"AM"
            this.setState({
                toTime: e.target.value,
                toTimeStore: e.target.value + " " + meridiem
            })

            setTimeout(()=>{
                this.setState({ 
                    data: {...this.state.data, 'operating_time' : this.state.fromDayValue+" "+ "to" + " "+ this.state.toDayValue+ " "+ this.state.fromTimeStore+ " " + "to" + " " + this.state.toTimeStore }
                }); 
            }, 100)
        }
        else{
            this.setState({ 
                data: {...this.state.data, [e.target.name] : e.target.value}
            });
        }
    }
     
    handleImageChange = (e) =>{
        if(e){
            if(e.target.name == 'featured_image'){
                this.setState({
                    featured_image_upload: true,
                    gallery_images_upload: false,
                    crop: { ...this.state.crop, aspect: 1/1 }
                })
            } else {
                this.setState({
                    featured_image_upload: false,
                    gallery_images_upload: true,
                    crop: { ...this.state.crop, aspect: 1.5 / 1 }
                })
            }
            const reader = new FileReader();
            
            if(reader && e.target.files[0]){
                reader.addEventListener('load', () =>
                    this.setState({ image: reader.result, openImageCrop: true })
                );
                reader.readAsDataURL(e.target.files[0])
            }
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {            
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );          
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {    
              return;
            }
            blob.name = fileName;
            this.setState({
                blob: blob
            })
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
    }

    handleAutoComplete = (e, v) => {
        if(v){
        /*    if(e == 'cuisines'){
                if(v.length > 0 ){
                    v.map((item)=>{
                        delete item["_id"]
                        this.setState({
                            data: {...this.state.data, [e]: [...this.state.data[e], item.title]}
                        })
                    })
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }*/

                   if(e == 'cuisines'){
                if(v.length > 0 ){

           console.log(v,"ve")


            this.setState({
                           data: {...this.state.data, [e]: v}
                        })



                    console.log(this.state.data.cuisines,"afterautocomplete")
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }

            else if(e == 'sf'){
                if(v.length > 0 ){

                        this.setState({
                           data: {...this.state.data, [e]: v}
                        })

                 /*   v.map((item)=>{
                        delete item["_id"]
                        this.setState({
                            data: {...this.state.data, sf: [...this.state.data.sf, item.sf]}
                        })
                    })*/
                    console.log(this.state.data.sf, 'Chk')
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }
                 else if(e == 'featuresTags'){
                if(v.length > 0 ){


                          this.setState({
                           data: {...this.state.data, [e]: v}
                        })

/*
              v.map((item)=>{
                        delete item["_id"]
                        this.setState({
                            data: {...this.state.data, featuresTags: [...this.state.data.featuresTags, item.ft]}
                        })
                    })*/
                    console.log(this.state.data.featuresTags, 'Chkft')
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }
            else if(e == 'dp'){
                if(v.length > 0){
                    v.map((item)=>{
                        this.setState({
                            data: {...this.state.data, "delivery": [...this.state.data.delivery, { "partnerLink": "", "partnerLogo": item.deliveryPartnerLogo, "partnerName": item.deliveryPartnerName }]}
                        })
                    })
                }
                if(v.length == 0){
                    this.setState({
                        data: {...this.state.data, "delivery": []}
                    })
                }
            }
            else if(e == 'area'){
                if(v){
                    this.setState({
                        data: {...this.state.data, [e]:  v["label"]  }
                    })
    
                }
            }   
            else if(e == 'fromDay'){
                if(v){
                    this.setState({
                        fromDayValue: v
                    })
    
                    let index = this.state.fromDay.indexOf(v)
                    let tempArray = this.state.fromDay
    
                    for(var i = index; i >= 0; i--){                    
                        tempArray.splice(i, 1)
                    }
                    setTimeout(()=>{ this.setState({ toDay: tempArray }) }, 100)
                }
            }
            else if(e == 'toDay'){
                if(v){
                    this.setState({
                        toDayValue: v
                    })
                }
            }
            else if(e == 'priceRange'){
                console.log(v)
                if(v){
                    this.setState({
                        data: {...this.state.data, "priceRange": v}
                    })
                }
            }
            else if(e == 'category'){
                if(v){
                    this.setState({
                        data: {...this.state.data, "category": v['ctry']}
                    })
                }
            }
            else if(e == 'city'){
                if(v){
                    this.setState({
                        data: {...this.state.data, "city": v['ct']}
                    })
                }
            }
            else{
                if(v){
                    this.setState({
                        data: {...this.state.data, [e]: v['ft']}

                        

                    })

                }
            }
            console.log(this.state.data)
        }
    }

    selectArea = (v)=>{
        this.setState({
            data: {...this.state.data, "area": v.value}
        })
    }

    handleSelectChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        setTimeout(()=> {
            if(this.state.fromDay == this.state.toDay){
                alert("Not allowed")
            }
        }, 500)
    }

    handleSwitchChange = (e) =>{
        this.setState({ data: {...this.state.data, 'featured': e.target.checked}})
    }

    deleteImag = (index) =>{
                
        fetch(produrl+"/deleteImage",{
            method: "POST",
            body: JSON.stringify({"fileName": this.state.data.gallery_images[index].split('/')[4] })            
        })
        .then(res => res.json())
        .then((data)=>{
            this.state.data.gallery_images.splice(index, 1)
            this.setState({
                data: {...this.state.data, "gallery_images": this.state.data.gallery_images}
            })
        })
        .catch(err =>{
            alert("Something went wrong")
        })
    }

    handleClose = () =>{
        this.setState({
            openImageCrop: false
        })
    }

    uploadCropedImage = () =>{
        this.setState({
            imageUploading: true
        })
        let form_data = new FormData();      

        form_data.append('image', this.state.blob);                   
        
        fetch(produrl+"/getImage",{
            method: "POST",
            body: form_data
        })
        .then((res)=> res.json())
        .then((data)=>{        
            if(this.state.featured_image_upload === true){                
                this.setState({
                    openImageCrop: false,
                    featured_image_upload: false,
                    imageUploading: false,
                    data: {...this.state.data, "featured_image": data['data']}                
                })
            }else if(this.state.gallery_images_upload === true){
                this.setState({
                    openImageCrop: false,
                    gallery_images_upload: false,
                    imageUploading: false,
                    data: {...this.state.data, "gallery_images": [...this.state.data.gallery_images, data['data']] }                
                })
            }
        })
    }


    onDescriptionsSave = (data) => {        
        let htmlContent = stateToHTML(this.state._editorState.getCurrentContent());        

        const contentHTML = convertFromHTML(htmlContent);

        const state = ContentState.createFromBlockArray(
            contentHTML.contentBlocks,
            contentHTML.entityMap
        );

        this.setState({
            contentData: JSON.stringify(convertToRaw(state)),
            data: { ...this.state.data, "description": htmlContent }
        })
    }

    handleDpChange = (e, index) =>{
        this.state.data.delivery[index]['partnerLink'] = e.target.value
    }

    selectTime = (key, e, value) =>{
        if(e){
            console.log(e)
            this.state.fromDay.map((item, index)=>{
                if(item == key){
                    this.state.timings[index][key][e] = value
                    this.setState({
                        timings: this.state.timings
                    }) 
                }
            })
        }

        setTimeout(()=>{
            if(this.state.timings[0]['Monday']['fromTime'] && this.state.timings[0]['Monday']['toTime']){
                console.log("SetState", "enable button")        
                this.setState({
                    applyForAllTimingsButton: false
                })
            }
            else if(!this.state.timings[0]['Monday']['fromTime'] || !this.state.timings[0]['Monday']['toTime']){
                console.log("SetState", "disable button")        
                this.setState({
                    applyForAllTimingsButton: true,

                })
            }
        }, 300)
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    applyValueForAll = () => {
        const fromTime = this.state.timings[0]['Monday']['fromTime']
        const toTime = this.state.timings[0]['Monday']['toTime']
        
        this.state.fromDay.map((item, index)=>{            
            this.state.timings[index][item]['toTime'] = toTime            
            this.state.timings[index][item]['fromTime'] = fromTime
        })

        if(this.state.changeToMultipleTimings){
            const fromTime1 = this.state.timings[0]['Monday']['fromTime1']
            const toTime1 = this.state.timings[0]['Monday']['toTime1']
            
            this.state.fromDay.map((item, index)=>{            
                this.state.timings[index][item]['toTime1'] = toTime1           
                this.state.timings[index][item]['fromTime1'] = fromTime1
            })  
        }

        this.setState({
            timings: this.state.timings
        })
    }

    validateTimings = () =>{
        let numberOfFieldsRequired = 0;

        this.state.fromDay.map((item, index)=>{
            if(this.state.timings[index][item]['selected']){
                
                if(this.state.timings[index][item]['fromTime'] == "No"){
                    this.state.timings[index][item]['toTime'] = "No"
                }
                
                if(!this.state.timings[index][item]['fromTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['fromTimeError'] = true                  
                }
                else if(!this.state.timings[index][item]['toTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['toTimeError'] = true               
                }
                else {                    
                    this.state.timings[index][item]['toTimeError'] = false
                    this.state.timings[index][item]['fromTimeError'] = false 
                    this.state.timings[index][item]['toTimeError1'] = false
                    this.state.timings[index][item]['fromTimeError1'] = false
                }
            }
        })        
        this.setState({
            timings: this.state.timings,
            openSelectHours: !numberOfFieldsRequired == 0,
            validateOkay: numberOfFieldsRequired == 0,            
        })

        if(numberOfFieldsRequired == 0){
            let filterTimings = []
            this.state.fromDay.map((item, index)=>{
                if(this.state.timings[index][item]['selected']){
                    let obj = {
                        [item]: {
                            fT: this.state.timings[index][item]['fromTime'],
                            tT: this.state.timings[index][item]['toTime']
                        }
                    }
                    filterTimings.push(obj)
                }
            })    
            console.log(filterTimings)        
            this.setState({
                data: {...this.state.data, 'operatingTime': filterTimings} 
            })
        }
    }

    validateMultipleTimings = () =>{
        let numberOfFieldsRequired = 0;

        this.state.fromDay.map((item, index)=>{                     
            if(this.state.timings[index][item]['selected']){
                if(this.state.timings[index][item]['fromTime'] == "No"){
                    this.state.timings[index][item]['toTime'] = "No"
                }                
                if(this.state.timings[index][item]['fromTime1'] == "No"){
                    this.state.timings[index][item]['toTime1'] = "No"
                }

                if(!this.state.timings[index][item]['fromTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['fromTimeError'] = true                  
                }
                if(!this.state.timings[index][item]['toTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['toTimeError'] = true               
                }
                
                if(!this.state.timings[index][item]['fromTime1']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['fromTimeError1'] = true                  
                }
                if(!this.state.timings[index][item]['toTime1']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['toTimeError1'] = true                  
                }
                else {
                    this.state.timings[index][item]['toTimeError'] = false
                    this.state.timings[index][item]['fromTimeError'] = false 
                    this.state.timings[index][item]['toTimeError1'] = false
                    this.state.timings[index][item]['fromTimeError1'] = false
                }
            }
        })                
        
        this.setState({
            timings: this.state.timings,
            openSelectHours: !numberOfFieldsRequired == 0,
            validateOkay: numberOfFieldsRequired == 0,            
        })

        if(numberOfFieldsRequired == 0){
            let filterTimings = []
            this.state.fromDay.map((item, index)=>{
                if(this.state.timings[index][item]['selected']){
                    let obj = {
                        [item] : { 
                            fT: this.state.timings[index][item]['fromTime'],
                            tT: this.state.timings[index][item]['toTime'],
                            fT1: this.state.timings[index][item]['fromTime1'],
                            tT1: this.state.timings[index][item]['toTime1'],
                        }
                    }
                    filterTimings.push(obj)
                }
            })
            this.setState({
                data: {...this.state.data, 'operatingTime': filterTimings} 
            })
        }
    }

    handleDayTimingRemove = (index, item) =>{
        this.state.timings[index][item]['selected'] = !this.state.timings[index][item]['selected'] 
        this.setState({ timings: this.state.timings })
    }

    handleSubmit = (e) => {
        e.preventDefault();           
       //jeg if(this.state.data.featured_image != '' && this.state.data.gallery_images.length >= 3 && this.state.validateOkay){            

if(this.state.data.featured_image =='')
{
    this.state.data.featured_image ="956a99b5cfc445cbbae0f17a3126279e.jpg";
}
         if(this.state.data.featured_image != '' && this.state.validateOkay){            
            this.setState({
                loading: true
            })
            this.onDescriptionsSave()
            setTimeout(()=> {
                this.state.data.routeField = loadsh.camelCase(this.state.data.name)   
                console.log(this.state.data)     
                fetch(url+"/addNewrestaurants",{
                    method: "POST",
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify(this.state.data)
                })
                .then((res)=> res.json())
                .then((data)=> {
                    if(data['status']=="okay"){                
                        this.setState({      
                            setSnack:true,
                            message:'New restaurant added successfully',
                            msgstatus:"success"                                
                        })
                        setTimeout(()=>{
                            this.setState({
                                loading: false
                            })
                            this.props.history.push('/')
                        }, 2000)
                    }
                })
                .catch((err)=>{
                    this.setState({        
                        setSnack:true,
                        message:'Something went wrong',
                        msgstatus:"error",
                        loading: false
                    })
                })
            }, 300)            
        }
        else if(!this.state.validateOkay){
            this.setState({        
                setSnack:true,
                message:'Select operating hours',
                msgstatus:"error"
            })
        }
        else if(this.state.data.featured_image == ''){
            this.setState({        
                setSnack:true,
                message:'Featured image is required!',
                msgstatus:"error"            
            })
        }
        else if(this.state.data.gallery_images.length < 3 ){
            this.setState({        
                setSnack:true,
                message:'Minimum three images required for gallery!',
                msgstatus:"error",
                invisible: true
            })
        }        
    }

    addCuisine(){ 
        const cuisine = '';
      
        if(cuisine){
            fetch(url+"/addCuisine",{
                method: "POST",
                body: JSON.stringify({"title": cuisine, "data": cuisine })
            })
            .then(res => res.json())
            .then((data)=>{
                if(data['status']=='okay' && data['response']=='added'){                
                    this.getdata()
                    this.setState({        
                        setSnack:true,
                        message:'New cuisine added',
                        msgstatus:"success"
                    })
                } else if(data['status']=='okay' && data['response']=='exist'){
                    this.setState({        
                        setSnack:true,
                        message:'Cuisine already exist',
                        msgstatus:"error"
                    })             
                }
                else{
                    this.setState({        
                        setSnack:true,
                        message:'Something went wrong',
                        msgstatus:"error"
                    })
                }
            })
            .catch(err =>{
                this.setState({        
                    setSnack:true,
                    message:'Something went wrong',
                    msgstatus:"error"
                })
            })
        }else{
            this.setState({        
                setSnack:true,
                message:'Cuisine name should not be empty',
                msgstatus:"error"
            })        
        }
    }
   
  
    render(){
        const { image, crop, openImageCrop, data, imageUploading, loading, openSelectHours, applyForAllTimingsButton, showcuisine,showarea, showcity,cuisine } = this.state;          
        console.log(data.priceRange)
        return(
            <div style={{width: "100%"}}>
            {loading ? 
                <div style={{width: "100%", height: "90vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                    <CircularProgress/>
                </div> :            
                
                <div style={{width: "100%", display: 'flex', flexDirection: "column" }}>
                <Typography style={{margin: 10}} variant='h5'>Add New Restaurant</Typography>
                <form
                    ref="form"
                    onSubmit={this.handleSubmit}                    
                >
                        <div style={{display: 'flex', width: "100%", flexDirection: 'column', flexWrap: 'wrap' }} >
                            {formFields.map((item, index)=>
                                <div style={{display: 'flex', width: "100%", flexDirection: 'column', flexWrap: 'wrap'}} >
                                    
                                                                        

                                       {index == 1 && <div style={{width: "100%", margin: 10, display: 'flex'}} >

                                       <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}} >
                                                            <Typography style={{marginBottom: 5}}>Category *</Typography>
                                                <Autocomplete
                                                    options={this.state.categories} 
                                                    id="categories"
                                                    defaultValue={{ctry: data.category}}
                                                    onChange={(e, value) => this.handleAutoComplete("category", value)}
                                                    getOptionLabel={option => option['ctry']}
                                                    renderInput={params => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Eg Restaurant"
                                                        />
                                                    )}
                                                />
                                       </div>

                                            <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}} >
                                             <Typography style={{marginBottom: 5}}>Cuisines *</Typography>
                                            <Autocomplete
                                                multiple
                                                id="cuisines"
                                               options={this.state.cuisinesMenuTitles}
                                                getOptionLabel={option => option}                            
                                                onChange={(e, value) => this.handleAutoComplete("cuisines", value)}
                                                filterSelectedOptions
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Eg: Indian, Thai, Chinese"
                                                    />
                                                )}
                                            />
                                            </div>


     <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}} >
                                                <Typography style={{marginBottom: 5}}>Price range *</Typography>
                                                <Autocomplete
                                                    options={this.state.priceRanges} 
                                                    id="priceRange"
                                                    onChange={(e, value) => this.handleAutoComplete("priceRange", value)}
                                                    getOptionLabel={option => option}
                                                    defaultValue={data.priceRange}                                                                                                                                        
                                                    renderInput={params => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Eg Affordable"
                                                        />
                                                    )}
                                                />
                                            </div>

                                       </div>
                                   }
                                 {index == 1 &&    <div style={{width: "100%", margin: 10, display: 'flex', justifyContent: 'center'}} >
    <div style={{margin: 10, display: 'flex', flexDirection: 'column', flex: 1 }}>
    <Button variant="contained" color="primary" type="button" onClick={()=> this.setState({
    showcuisine: true
     })}>Add Cuisine</Button>
    </div>
    <div style={{ margin: 10,display: 'flex', flexDirection: 'column', flex: 1 }}>
    <Button variant="contained" color="primary" type="button" onClick={()=> this.setState({
    showarea: true
     })}>Add Area</Button>
    </div>
    <div style={{ margin: 10,display: 'flex', flexDirection: 'column', flex: 1 }}>
    <Button variant="contained" color="primary" type="button" onClick={()=> this.setState({
    showcity: true
     })} >Add City</Button>
    </div>
    </div>}
    {index == 1 &&   ( <div style={{width: "100%", margin: 10, display: 'flex', justifyContent: 'center'}} >
     {showcuisine===false?(""):(
        
    <div style={{margin: 10, display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <TextField
                   variant="outlined"
                   placeholder="Add Cuisine"
                   name="add_cuisine"
                    />
                     <Button type="button" color={'red'} onClick={()=> this.addCuisine()}>
                        submit
                    </Button>
                    <Button type="button" color={'red'} onClick={()=> this.setState({
                 showcuisine: false
                       })} >
                        close
                    </Button>
    </div>
    )}
    {showarea===false?(""):(
    <div style={{margin: 10, display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <TextField
                   variant="outlined"
                   placeholder="Add Area"
                    />
                    <Button type="button" color={'red'} onClick={()=> this.setState({
                 showarea: false
                       })} >
                        close
                    </Button>
    </div>
    )}

{showcity===false?(""):(
    <div style={{margin: 10, display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <TextField
                   variant="outlined"
                   placeholder="Add City"
                    />
                    <Button type="button" color={'red'} onClick={()=> this.setState({
                 showcity: false
                       })} >
                        close
                    </Button>
    </div>
    )}
    </div>)}
                                    {index == 1 && <div style={{width: "100%", margin: 10, display: 'flex'}} >
                                            <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                                <Typography style={{marginBottom: 5}}>Area *</Typography>
                                                <Autocomplete
                                                    options={this.state.area} 
                                                    id="area"
                                                    onChange={(e, value) => this.handleAutoComplete("area", value)}
                                                    getOptionLabel={option => option.value}                                                                                                                                        
                                                    renderInput={params => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Eg T Nagar"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>                                                
                                                <Typography style={{marginBottom: 5}}>City</Typography>
                                                <Autocomplete
                                                    options={this.state.cities} 
                                                    id="city"
                                                    defaultValue={{ct: data.city}}
                                                    onChange={(e, value) => this.handleAutoComplete("city", value)}
                                                    getOptionLabel={option => option['ct']}                                                    
                                                    renderInput={params =>(<TextField required {...params} variant="outlined" placeholder="Eg Chennai" />)}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {index == 4 && <Typography style={{ margin: "10px 10px 5px 10px" }} > Description</Typography> }
                                    {index == 4 ? 
                                        <div style={{width: "100%", margin: 10, display: 'flex', flexDirection: 'column'}} >
                                            <div style={{paddingLeft: 10, border: "1px solid rgba(0,0,0,0.2)", borderRadius: 5, height: 300 }} >
                                                <MUIRichTextEditor
                                                    toolbarButtonSize="medium"
                                                    controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote"]}
                                                    label="Type something here..."                            
                                                    onChange={(state)=> this.setState({_editorState: state}) }
                                                    value={this.state.contentData}
                                                    inlineToolbar={false}
                                                />                                         
                                            </div>
                                        </div>
                                        : ''                                        
                                    }
                                    {index == 4 &&
                                        <div style={{width: "100%", display: 'flex', alignItems: 'center', marginBottom: 5}} >                                             
                                            <Typography style={{ margin: "10px" }} >Opening hours *</Typography> 
                                            <div><Button variant="outlined" size='small' color={ this.state.validateOkay ? 'primary': 'secondary'} onClick={()=> this.setState({
                                                openSelectHours: true
                                            })} >Select hours</Button></div>
                                        </div>
                                    }
                                    {index == 4 &&
                                        <div style={{width: "100%", marginLeft: 10, display: 'flex', flexWrap: 'wrap', marginBottom: 10}} >                                            
                                        {this.state.validateOkay && this.state.timings.map((item, index)=>
                                            Object.keys(item).map((keyName, index)=>
                                                item[keyName]['selected'] && <Chip label={item[keyName]['fromTime1'] ? keyName+' '+'FN'+" "+item[keyName]['fromTime']+" "+'to'+" "+item[keyName]['toTime']+" "+'AN'+" "+ item[keyName]['fromTime1']+" "+"to"+" "+item[keyName]['toTime1'] : keyName+' '+item[keyName]['fromTime']+" "+'to'+" "+item[keyName]['toTime']} style={{marginRight: 10, marginBottom: 10}}/>
                                            )                                            
                                        )}                                                                                        
                                        </div>
                                    }
                                    
                                    {item.parallel ? 
                                        <div style={{width: "100%", margin: 10, display: 'flex'}} >
                                            <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                                <Typography style={{marginBottom: 5}}>{item.label} {item.required && "*"}</Typography>
                                                <TextField
                                                    placeholder={item.placeholder}
                                                    key={index}
                                                    required={item.required}
                                                    onChange={this.handleChange}
                                                    multiline={item.multiline}
                                                    rows={item.noOfRows}
                                                    variant="outlined"
                                                    type={item.type}
                                                    name={item.name}
                                                    value={ item.name == 'youtube_url' ? this.state.data[item.name] ? "https://www.youtube.com/watch?v="+this.state.data[item.name] : '' : this.state.data[item.name]}
                                                />
                                            </div>
                                            <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                                <Typography style={{marginBottom: 5}} >{item.label1} {item.required1 && "*"}</Typography>
                                                <TextField
                                                    placeholder={item.placeholder1}
                                                    style={{flex: 1}}
                                                    key={index}
                                                    required={item.required1}
                                                    onChange={this.handleChange}
                                                    multiline={item.multiline1}
                                                    rows={item.noOfRows1}
                                                    variant="outlined"
                                                    type={item.type1}
                                                    name={item.name1}
                                                    value={this.state.data[item.name1]}
                                                />
                                            </div>
                                        </div> : 
                                        <div style={{width: "100%", margin: 10, display: 'flex'}} >
                                            <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                                <Typography style={{marginBottom: 5}}>{item.label} {item.required && "*"}</Typography>
                                                <TextField
                                                    placeholder={item.placeholder}
                                                    key={index}
                                                    required={item.required}
                                                    onChange={this.handleChange}
                                                    multiline={item.multiline}
                                                    rows={item.noOfRows}
                                                    variant="outlined"
                                                    type={item.type}
                                                    name={item.name}
                                                    value={this.state.data[item.name]}                            
                                                />
                                            </div>                                    
                                        </div>                                
                                    }
                              
                                    { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 5}}>Special foods *</Typography>
                                            <Autocomplete
                                                multiple
                                                id="sf"
                                                options={this.state.sflist}
                                                getOptionLabel={option => option}                            
                                                onChange={(e, value) => this.handleAutoComplete("sf", value)}
                                                filterSelectedOptions                            
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Eg: Shawarma, Tandoori, Tacos"
                                                    />
                                                )}
                                            />
                                        </div>
                                    }

                                    { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 5}}>Features & tags</Typography>
                                            <Autocomplete
                                                id="ft"
                                                multiple
                                                options={this.state.ft}                            
                                                getOptionLabel={option => option}                            
                                                onChange={(e, value) => this.handleAutoComplete("featuresTags", value)}                                                
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Eg: Vallet parking"
                                                    />
                                                )}
                                            />
                                        </div>
                                    }

                                    { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 5}}>Delivery partners</Typography>
                                            <Autocomplete
                                                multiple
                                                id="dp"
                                                options={this.state.deliveryPartners}                            
                                                getOptionLabel={option => option.deliveryPartnerName}                            
                                                onChange={(e, value) => this.handleAutoComplete("dp", value)}
                                                filterSelectedOptions                            
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Eg: Food panda"
                                                    />
                                                )}
                                            />
                                        </div>
                                    }
                                    { index == 7 && this.state.data.delivery.map((item, i) =>                 
                                            <div style={{display: 'flex', width: "100%", flexDirection: 'column', flexWrap: 'wrap' }} >                        
                                                <TextField
                                                    style={{margin: 10, width: "100%"}}
                                                    key={i}
                                                    label={item.partnerName+" "+"link"}
                                                    onChange={(e)=> this.handleDpChange(e, i)}
                                                    variant="outlined"
                                                    type={item.type}
                                                    required
                                                    name={item.name}
                                                    value={this.state.data[item.name]}                            
                                                />
                                            </div>
                                        )
                                    }

                                    
                                    { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 10}}>Featured image</Typography>
                                            { data.featured_image == '' ? 
                                                <div>
                                                    <Button   
                                                        size="small" 
                                                        color="primary"
                                                        variant="outlined" 
                                                        onClick={()=> document.getElementById('featured_image').click()}
                                                    >
                                                        Select image
                                                    </Button>
                                                </div> :
                                                <Card style={{width: 280, boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.4)"}}>                                                                                            
                                                    <img src={prefixImageUrl+data.featured_image} style={{width: 280, height: 300, objectFit: 'contain'}}/>
                                                    <CardActions>
                                                        <div style={{display: 'flex', flex: 1}}>                                
                                                            <Button  size="small" color="secondary" variant="outlined" onClick={()=> document.getElementById('featured_image').click()}>
                                                                Change
                                                            </Button>
                                                        </div>
                                                    </CardActions>
                                                </Card>                                                
                                            }
                                        </div>
                                    }

                                    { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 10}}>Gallery images</Typography>
                                            <div >                                                                        
                                                <Button  size="small" color={this.state.invisible ? "secondary":"primary"} variant="outlined" onClick={()=> this.setState({ openGalleryImageDialog: true }) }>
                                                    { this.state.data.gallery_images.length > 0 ? this.state.data.gallery_images.length > 1 ? "View gallery images" : "View gallery image" : "Add gallery images"}
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                            <input
                                hidden
                                type="file"
                                style={{padding: 10, margin: 10,}}
                                // required                        
                                id="featured_image"
                                accept="image/png, image/jpeg" 
                                name="featured_image"
                                onChange={this.handleImageChange}
                            />
                            <input
                                hidden
                                type="file"
                                style={{padding: 10, margin: 10,}}                        
                                id="gallery_image"
                                accept="image/png, image/jpeg" 
                                name="gallery_image"
                                onChange={this.handleImageChange}
                            />
                        </div>


                    <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} >
                        <div style={{ width: "100%" , display: 'flex'}}>                        
                            <div  style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}><Button variant="contained" color="primary" type="submit" >Add new restaurant</Button></div>
                        </div>
                    </div>
                </form>

                <Dialog maxWidth  open={openImageCrop} >
                    <DialogTitle>Resize image</DialogTitle>
                    <DialogContent style={{ minHeight: 600 }}>                     
                    <ReactCrop
                        src={image}
                        style={{ minHeight: 600, width: this.state.openGalleryImageDialog ? 850 : 600 }}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='secondary' onClick={this.uploadCropedImage} >
                            {imageUploading ? "Uploading" : "Save"}
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openImageCrop: false })} >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth  open={openSelectHours}>
                    <DialogTitle>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', }}> 
                            <Typography component='h5' variant='h5' >Select operating hours *</Typography>
                            <div>
                                <FormControlLabel
                                    label="Multiple timings"
                                    control={
                                        <Switch 
                                            checked={this.state.changeToMultipleTimings} 
                                            onChange={()=> this.setState({ changeToMultipleTimings:  !this.state.changeToMultipleTimings })} 
                                            name="checkedA"
                                        />
                                    }
                                />
                                <Button variant='outlined' color="primary" size='small' disabled={applyForAllTimingsButton} onClick={this.applyValueForAll} >Apply for all</Button>
                            </div>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{ minHeight: 400, minWidth: 900}}>                        
                            { this.state.changeToMultipleTimings && <div style={{display: 'flex'}} >
                                <Typography style={{width: 160}} >{""}</Typography>
                                <Typography style={{ flex: 1 }} align="center" >Morning</Typography>
                                <Typography style={{ flex: 1 }} align="center" >After noon</Typography>
                            </div>}
                            {this.state.fromDay.map((item, index)=>
                                <div style={{width: "100%", margin: 10, display: 'flex', alignItems: 'center'}} >                                    
                                    <Typography style={{width: 100}} >{item}</Typography>
                                    <Checkbox
                                        checked={this.state.timings[index][item]['selected']}
                                        color="primary"
                                        onChange={()=> this.handleDayTimingRemove(index, item)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <Autocomplete
                                        options={this.state.formTimeMenu}
                                        id={item+"FromTime"}
                                        value={this.state.timings[index][item]['fromTime']}
                                        onChange={(e, value) => this.selectTime(item, "fromTime", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 5 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                error={this.state.timings[index][item]['fromTimeError']}
                                                {...params}
                                                variant="outlined"
                                                placeholder="10:00 AM"
                                            />
                                        )}
                                    />
                                   <Autocomplete
                                        options={this.state.formTimeMenu} 
                                        id={item+"ToTime"}
                                        value={this.state.timings[index][item]['toTime']}
                                        onChange={(e, value) => this.selectTime(item, "toTime", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 20 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={this.state.timings[index][item]['toTimeError']}
                                                placeholder="11:00PM"
                                            />
                                        )}
                                    />       
                                    { this.state.changeToMultipleTimings && <Autocomplete
                                        options={this.state.formTimeMenu}
                                        id={item+"FromTime1"}
                                        value={this.state.timings[index][item]['fromTime1']}
                                        onChange={(e, value) => this.selectTime(item, "fromTime1", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 5 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                error={this.state.timings[index][item]['fromTimeError']}
                                                {...params}
                                                variant="outlined"
                                                placeholder="10:00 AM"
                                            />
                                        )}
                                    />}
                                   { this.state.changeToMultipleTimings && <Autocomplete
                                        options={this.state.formTimeMenu} 
                                        id={item+"ToTime1"}
                                        value={this.state.timings[index][item]['toTime1']}
                                        onChange={(e, value) => this.selectTime(item, "toTime1", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 5 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={this.state.timings[index][item]['toTimeError']}
                                                placeholder="11:00PM"
                                            />
                                        )}
                                    />}
                                </div>
                            )}                        
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='secondary' onClick={()=> this.state.changeToMultipleTimings ? this.validateMultipleTimings() : this.validateTimings()} >
                            Save
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openSelectHours: false })}  >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth open={this.state.openGalleryImageDialog} onClose={()=> this.setState({ openGalleryImageDialog: false })} >
                    <DialogTitle>Gallery images * three images minimum required</DialogTitle>
                    <DialogContent style={{ minHeight: 400, width: 1075, display: 'flex', flexWrap: 'wrap' }}>
                        {
                            this.state.data.gallery_images &&  this.state.data.gallery_images.map((item, i)=>
                                <Card style={{width: 300, margin: "0px 10px", boxShadow: 'none' }} >
                                    <img src={prefixImageUrl+item} style={{ height: 170, width: 300,  objectFit: 'cover' }} />
                                    <CardActions>                                                                        
                                        <Button variant='outlined'  size="small" color="secondary" onClick={ ()=>this.deleteImag(i)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        }                    

                    <Button style={{ height: 170, width: 300, display: 'flex',  justifyContent: 'center', alignItems: 'center' }} onClick={()=> document.getElementById('gallery_image').click()} variant='outlined' >
                         <div> <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} > <AddIcon/> Add images </div> </div> 
                    </Button>
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openGalleryImageDialog: false })} >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>}
            </div>
        )
    }
}

export default withRouter(AddNewRestaurent);