import React from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Typography, Card, CardMedia, IconButton, CardActions, CardActionArea,CardContent , Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import { url, produrl } from './constant'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';

class AllPromo extends React.Component{
    constructor(){
        super()
        this.state = {
            columns: [                
                {
                    "title": "Restaurant ",
                    "field": "name"
                },
                {
                    "title": "Secret key",
                    "field": "secretKey"
                },
                {
                    "title": "Number of promotions",
                    "align": "center",
                    "field": "noOfPromotions"
                }
            ],           
            data:[],
            openDialog: false,
            deleteData: {},
            limit: 10,
            dataFetch: [],
            skip: 0,
            loading: false,
        }
    }
    componentDidMount(){
        this.handleSearchSubmit(10, 0, false)          
    }

    handleSearchSubmit = async (limit, skip, frmOnClr) =>{
        this.setState({
            loading: true
        })        
        if(frmOnClr){         
            this.setState({
                data: []              
            })       
        }               
        await fetch(url+'/getrestaurants1', {
            method: "POST",
            body: JSON.stringify({"data": {}, "skip": skip, "limit": limit})
        })
        .then(res =>res.json())
        .then((data)=>{                        
            this.setState({
                resLength: data['length'],                
            })
            var restaurantDetails = data['response']
            restaurantDetails.map((item, index)=>{
                fetch(url+"/getNumberOfPromo", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({"id":  item['_id']['$oid'] })
                })
                .then(res => res.json())
                .then((data)=>{      
                    if(data['status']=='okay'){
                        let promoData = data['response']                    
                        
                        if(promoData['length'] > 0){
                            let restaurantId =  promoData['res_id']
                            let numberOfPromos = promoData['length']
                            
                            if(item['_id']['$oid'] == restaurantId){
                                restaurantDetails[index]['noOfPromotions'] = numberOfPromos
                            }                                                             
                        } else if(promoData['length'] == 0){
                            restaurantDetails[index]['noOfPromotions'] = 0                            
                        }
                    }
                })
            })        

            setTimeout(()=> 
                this.setState({
                    data: this.state.data.concat(restaurantDetails),
                    loading: false
                }), 
                3000 
            )
        })
        .catch((err) =>{
            this.setState({
                loading: false
            })
        })
    }

    fetchRestaurant = e => {
        if(e){
            this.setState({
                dataFetch: []
            })
            fetch(url+'/fetchRestaurantAutoComplete',{
                method: "POST",
                body: JSON.stringify({"name": {"$regex": e.target.value, $options:'i'}})
            })
            .then(res=> res.json())
            .then((data)=>{
                if(data['status'] == "okay"){    
                    this.setState({
                        dataFetch: data['response']
                    })                    
                }
            })
        }
    }

    handleOnSelect = (v)=>{
        if(v){
            const data = [v]            
            var restaurantDetails = data;

            restaurantDetails.map((item, index)=>{
                fetch(url+"/getNumberOfPromo", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({"id":  item['_id']['$oid'] })
                })
                .then(res => res.json())
                .then((data)=>{      
                    if(data['status']=='okay'){
                        let promoData = data['response']                    
                        
                        if(promoData['length'] > 0){
                            let restaurantId =  promoData['res_id']
                            let numberOfPromos = promoData['length']
                            
                            if(item['_id']['$oid'] == restaurantId){
                                restaurantDetails[index]['noOfPromotions'] = numberOfPromos
                            }                                                             
                        } else if(promoData['length'] == 0){
                            restaurantDetails[index]['noOfPromotions'] = 0                            
                        }
                    }
                })
            })        
            this.setState({
                data: [],
            })
            setTimeout(()=> 
                this.setState({                
                    data: this.state.data.concat(restaurantDetails),
                    loading: false
                }),
                1000 
            )
        }
    }

    deletePromo(oldData){
        fetch(url+"/deletePromo",{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"id": oldData['_id']['$oid']})
        })
        .then((res)=> res.json())
        .then((data)=>{            
            if(data["status"]==="okay"){
                alert("Deleted successfully")
                this.setState({
                    openDialog: false
                })
                this.handleSearchSubmit(20, 0, false)
            }
        })
    }

    render(){
        const { columns, data, deleteData, openDialog } = this.state
        return(
            <div style={{width: "100%"}}>  
                <Typography variant='h5' component='h5' gutterBottom>Promotions</Typography>          
                <Autocomplete
                    style={{width: "100%", margin: "20px 0px"}}                            
                    options={this.state.dataFetch}
                    closeIcon={ <ClearIcon onClick={()=> data.length < 20 && this.handleSearchSubmit(10, 0, true)} />}
                    id="area"
                    onChange={(e, value) => this.handleOnSelect(value)}
                    getOptionLabel={option => option.name}
                    value=''                             
                    renderInput={params => (
                    <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Select restaurant"
                        placeholder="Select restaurant"
                        onChange={this.fetchRestaurant}
                    />
                    )}
                />
                <MaterialTable 
                    columns={columns} 
                    data={data} 
                    options={{
                        toolbar: false,
                        pageSizeOptions: [5],
                        pageSize: 5
                    }}
                    isLoading={this.state.loading}      
                    onChangePage={(page)=> (10 * page) == data.length ? this.handleSearchSubmit(10, (10 * page) + 10, false) : '' }
                    onRowClick={ (event, rowData) => rowData['noOfPromotions'] > 0 ? this.props.history.push({pathname: '/allPromos/'+rowData["_id"]['$oid'], state: {data: rowData['promoData'], title: rowData['name'] }}) : alert("The selected restaurant has no promotions") }
                    title="All promos"                    
                />
                <Dialog
                    open={openDialog}
                    onClose={()=> this.setState({openDialog: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Are you sure do you want to delete</DialogTitle>

                    <DialogActions>
                    <Button onClick={()=> this.setState({openDialog: false})} color="primary">
                        No
                    </Button>
                    <Button onClick={()=> this.deletePromo(deleteData)} color="secondary" autoFocus>
                        Yes
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(AllPromo)