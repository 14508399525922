import React from 'react'
import { withRouter } from "react-router-dom";
import { FormControlLabel, Checkbox, Switch, Button, Typography, Snackbar, Stepper, CircularProgress,Step, StepLabel, Card, CardMedia, CardActions, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { url, produrl, formFields, formFieldsCntue, prefixImageUrl } from './constant'
import { v4 as uuidv4 } from 'uuid';
import MUIRichTextEditor from "mui-rte";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import ReactHtmlParser from 'react-html-parser'; 
import DeleteIcon from '@material-ui/icons/Delete';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import AddIcon from '@material-ui/icons/Add';

import loadsh from 'loadsh'
import Alert from './Alert'

const steps = [ 'General', 'Images & others'];

const sampleMarkup = ""

class Restaurent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openImageCrop: false,
            image: '',
            crop: {
                unit: '%',
                width: 100,
                aspect: 1 / 1,
            },
            editDescription: false,
            comments: [],
            edit: true,
            promos: [],
            data: this.props.location.state.data,
            area: [],
            ft: [],
            cuisinesMenu: [],        
             cuisinesMenuTitles: [],     
            activeStep: 0,
            isDialogOpen: false,
            contentData: '',
            openGalleryImageDialog: false,
            openSelectHours: false,
            applyForAllTimingsButton: this.props.location.state.data.operatingTime ? false : true,
            deliveryPartners: [],
            timings: [
                {"Monday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": false}},
                {"Tuesday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": false}},
                {"Wednesday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '',"fromTimeError": false, "toTimeError": false, "selected": false}},
                {"Thursday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": false}},
                {"Friday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": false}},
                {"Saturday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": false}},
                {"Sunday": {"fromTime": '', "toTime": '', "fromTime1": '', "toTime1": '', "fromTimeError": false, "toTimeError": false, "selected": false}}
            ],
            validateOkay: this.props.location.state.data.operatingTime ? true : false,
            fromDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            formTimeMenu: [
                '12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM', 'No' 
            ],
            toTimeMenu: [
                '12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM', 'No' 
            ],
            fromDayValue: this.props.location.state.data.operating_time ? this.props.location.state.data.operating_time.split(' ')[0] : '',
            toDayValue: this.props.location.state.data.operating_time ? this.props.location.state.data.operating_time.split(' ')[2] : '',
            fromTime: this.props.location.state.data.operating_time ? this.props.location.state.data.operating_time.split(' ')[3] : '',
            toTime: this.props.location.state.data.operating_time ? this.props.location.state.data.operating_time.split(' ')[6] : '' ,
            loading: false,
            priceRanges: ['Affordable', 'Mid range', 'High end'],
            categories: [],
            changeToMultipleTimings: false,
            cities: [],
            sflist: []
        }
    }
    
    componentDidMount(){                
        if(this.props.location.state.data == undefined){
            this.props.history.push('/')
        }

        if(!this.state.data.currentStatus){
            this.state.data.currentStatus = 'Opened now'
        }
        
        if(!this.state.data.priceRange){
            this.state.data.priceRange = 'Affordable'
        }

        if(this.props.location.state.data.operatingTime){
            console.log(this.props.location.state.data.operatingTime)
            this.props.location.state.data.operatingTime.map((item, index)=>{
                Object.keys(item).map((key, index)=>{
                    this.state.fromDay.map((day, index)=>{
                        if(day == key){
                            this.state.timings[index][key]['selected'] = true
                            this.state.timings[index][key]['fromTime'] = item[key]['fT']
                            this.state.timings[index][key]['toTime'] = item[key]['tT']
                            
                            if(item[key]['fT1'] != undefined){
                                this.state.changeToMultipleTimings = true;
                                this.state.timings[index][key]['fromTime1'] = item[key]['fT1']
                                this.state.timings[index][key]['toTime1'] = item[key]['tT1']
                            }
                        }
                    })
                })
            })
            
            this.setState({
                timings: this.state.timings,
                changeToMultipleTimings: this.state.changeToMultipleTimings
            })
        }                

        fetch(url+"/getArea", {
            method: "GET"
        })
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                area: data['response']
            })
        })

        fetch(url+"/getFt", {
            method: "GET"
        })
        .then(res=>res.json())
     .then((data)=>{
         
                var ft = []
                data['response'].map(item =>{
                    ft.push(item.ft)
                })
                this.setState({ ft })
              console.log(this.state.ft,"loadFT")
        })
        fetch(url+"/getCuisine", {
            method: "GET"
        })
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                cuisinesMenu: data['response']
            })

                 this.state.cuisinesMenu.map((item)=>{
                        delete item["_id"]
                        this.setState({
                           ['cuisinesMenuTitles']: [...this.state['cuisinesMenuTitles'], item.title]
                        })
                    })

      console.log(this.state.cuisinesMenuTitles,"loadcuisines")
        })

        fetch(url+"/getDeliveryPartners",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    deliveryPartners: data['response']
                })
            }        
        })

        fetch(url+"/getTemp?collection=categories",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    categories: data['response']
                })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

        fetch(url+"/getTemp?collection=city",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    cities: data['response']
                })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

        fetch(url+"/getTemp?collection=specialFoods",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                var sflist = []
                data['response'].map(item =>{
                    sflist.push(item.sf)
                })
                this.setState({ sflist })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

        const contentHTML = convertFromHTML(this.state.data.description);

        const state = ContentState.createFromBlockArray(
            contentHTML.contentBlocks,
            contentHTML.entityMap
        );

        this.setState({
            contentData: JSON.stringify(convertToRaw(state))
        })
    }

    onDescriptionsSave = (data) => {
        console.log("Save descriptions")
        let htmlContent = stateToHTML(this.state._editorState.getCurrentContent());        

        const contentHTML = convertFromHTML(htmlContent);


        const state = ContentState.createFromBlockArray(
            contentHTML.contentBlocks,
            contentHTML.entityMap
        );

        this.setState({
            contentData: JSON.stringify(convertToRaw(state)),
            editDescription: false,
            data: { ...this.state.data, "description": htmlContent }
        })

        setTimeout(()=>{
            console.log(this.state.data.description)
        }, 300)
    };

    acceptReview(){
        fetch(url+'/acceptReview', {
            method: "POST",
            body: JSON.stringify({})
        })
        .then((res)=> res.json())
        .then((data)=>{ })
    }

    selectArea = (v)=>{
        this.setState({
            data: {...this.state.data, "area": v.value}
        })        
    }

    openDialog =()=>{
        this.setState({
            isDialogOpen: !this.state.isDialogOpen
        })
    }

    addNewPromo = (e) =>{
        e.preventDefault();
        this.state.promoFormData.promoCode = uuidv4()
        
        fetch(url+'/addPromo',{
            method: 'POST',
            body: JSON.stringify(this.state.promoFormData)
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='success'){
                alert('Promotion added successfully')
            }
        })
    }

    handleImageChange = (e) =>{
        if(e.target.name == 'featured_image'){
            this.setState({
                featured_image_upload: true,
                gallery_images_upload: false,
                crop: { ...this.state.crop, aspect: 1/1 }
            })
        }else{
            this.setState({
                featured_image_upload: false,
                gallery_images_upload: true,
                crop: { ...this.state.crop, aspect: 1.5 / 1 }
            })
        }
        const reader = new FileReader();
        if(reader && e.target.files[0]){
            reader.addEventListener('load', () =>
                this.setState({ image: reader.result, openImageCrop: true })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {            
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );          
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              return;
            }
            blob.name = fileName;
            this.setState({
                blob: blob
            })
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
    }

    uploadCropedImage = () =>{
        this.setState({
            imageUploading: true
        })
        let form_data = new FormData();      

        form_data.append('image', this.state.blob);                   
        
        fetch(url+"/getImage",{
            method: "POST",
            body: form_data
        })
        .then(res=> res.json())
        .then((data)=>{ 
            if(data['status']=='err'){
                console.log(data)
                alert("Something went wrong")
            }       
            else if(this.state.featured_image_upload === true){                 
                this.setState({
                    openImageCrop: false,
                    featured_image_upload: false,
                    imageUploading: false,
                    data: {...this.state.data, "featured_image": data['data']}                
                })
            }else if(this.state.gallery_images_upload === true){
                this.setState({
                    openImageCrop: false,
                    gallery_images_upload: false,
                    imageUploading: false,
                    data: {...this.state.data, "gallery_images": [...this.state.data.gallery_images, data['data']] }                
                })
            }
        })
        .catch(er =>{
            alert("Something went wrong")
        })
    }

    fileSelectHandler = (e, v) =>{        
        
        if(v){
            fetch(produrl+'/deleteImage',{
                method: "POST",
                body: JSON.stringify({"fileName": this.state.data['featured_image'].split('/')[4] })
            })
            .then(res => res.json())
            .then((data)=>{
                
            })
            .catch(err =>{
                
            })
        }        

        let form_data = new FormData();    

        Array.from(e.target.files).map((item, index)=>{
            form_data.append('image', item);                   
            
            fetch(produrl+"/getImage",{
                method: "POST",
                body: form_data
            })
            .then((res)=> res.json())
            .then((data)=>{
                if(e.target.name === "featured_image"){
                    this.setState({
                        data: {...this.state.data, "featured_image": data['data']}                
                    })
                }else{
                    this.setState({
                        data: {...this.state.data, "gallery_images": [...this.state.data.gallery_images, data['data']] }                
                    })
                }
            })
            .catch(err =>{
                
            })
        });
    }

    deleteImag = (index) =>{
                
        fetch(produrl+"/deleteImage",{
            method: "POST",
            body: JSON.stringify({"fileName": this.state.data.gallery_images[index].split('/')[4] })            
        })
        .then(res => res.json())
        .then((data)=>{
            this.state.data.gallery_images.splice(index, 1)
            this.setState({
                data: {...this.state.data, "gallery_images": this.state.data.gallery_images}
            })
        })
        .catch(err =>{
            
        })
    }

    handleChange = (e) =>{        
        if(e.target.name=='youtube_url'){
            let temp = e.target.value.split("=")
            this.setState({ 
                data: {...this.state.data, ['youtube_url'] : temp[1]}
            });

            setTimeout(()=>{
                console.log(this.state.data.youtube_url, this.state[e.target.name])
            }, 400)
        }
        else{
            this.setState({ 
                data: {...this.state.data, [e.target.name] : e.target.value}
            });
        }
    }

    handlePromoChange = (e) => {              
        this.setState({ 
            promoFormData: {...this.state.promoFormData, [e.target.id] : e.target.value}
        });
    }
    
    handleSelectChange = (e) =>{
        this.setState({ 
            promoFormData: {...this.state.promoFormData, "type" : e.target.value}
        });
    }

    handleEditImage = (multiple) =>{     
        if(multiple){
            this.setState({
                openGalleryImageDialog : true
            })
        }
        else{
            document.getElementById("featured_image").click();
        }
    }

    uploadEditedData = (e) =>{             
        e.preventDefault();        

        if(this.state.data.featured_image =='')
{
    this.state.data.featured_image ="956a99b5cfc445cbbae0f17a3126279e.jpg";
}
// original         if(this.state.data.gallery_images.length >= 3){
        if(1){
            if(this.state.data.featured_image != ''){
                this.setState({
                    loading: true
                })
                this.onDescriptionsSave()
                setTimeout(()=>{
                    let id = this.state.data._id['$oid']                    
                    let data = this.state.data     
                    delete data._id
                    this.state.data.routeField = loadsh.camelCase(this.state.data.name)
            
                    fetch(url+'/editRestaurant', {
                        method: "POST",
                        body: JSON.stringify({
                            "_id": id,
                            "data": data 
                        })
                    }).then(res => res.json())
                    .then((data)=>{            
                        if(data['status']=='okay'){
                            this.setState({      
                                setSnack:true,
                                message:'Restaurant details modified successfully',
                                msgstatus:"success",
                                loading: false                    
                            })

                            setTimeout(()=>{
                                this.setState({
                                    loading: false
                                })
                                this.props.history.push('/')
                            }, 2000)
                        }
                    })
                    .catch((err)=>{
                        this.setState({        
                            setSnack:true,
                            message:'Something went wrong',
                            msgstatus:"error",
                            loading: false
                        })
                    })
                }, 300)                
            }
        }
        else if(this.state.data.featured_image == ''){
            this.setState({        
                setSnack:true,
                message:'Featured image is required!',
                msgstatus:"error"            
            })
        }
        else if(this.state.data.gallery_images.length < 3 ){
            console.log("Hllo")
            this.setState({        
                setSnack:true,
                message:'Minimum three images required for gallery!',
                msgstatus:"error",
                invisible: true
            })
        }
    }

    handleAutoComplete = (e, v)=>{
        if(v){
          /*  if(e == 'cuisines'){
                this.setState({
                    data: {...this.state.data, [e]: [...this.state.data[e], v['title']]}
                })                                        
            }*/
                if(e == 'cuisines'){
                if(v.length > 0 ){

           console.log(v,"ve")


            this.setState({
                           data: {...this.state.data, [e]: v}
                        })


                /*    v.map((item)=>{
                       delete item["_id"]
                        this.setState({
                            data: {...this.state.data, cuisines: [...this.state.data.cuisines, item]}

               

                        })
                    })*/

                    console.log(this.state.data.cuisines,"afterautocomplete")
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }

    

            else if(e == 'dp'){                
                this.setState({
                    data: {...this.state.data, "delivery": [...this.state.data.delivery, { "partnerLink": "", "partnerLogo": v.deliveryPartnerLogo, "partnerName": v.deliveryPartnerName }]}
                })
            }
            else if(e == 'area'){
                if(v){
                    this.setState({
                        data: {...this.state.data, [e]:  v["label"]  }
                    })
                }            
            }
            else if(e == 'fromDay'){
                if(v){
                    this.setState({
                        fromDayValue: v
                    })
    
                    let index = this.state.fromDay.indexOf(v)
                    let tempArray = this.state.fromDay
    
                    for(var i = index; i >= 0; i--){                    
                        tempArray.splice(i, 1)
                    }
                    setTimeout(()=>{ this.setState({ toDay: tempArray }) }, 1000)
                }
            }
            else if(e == 'toDay'){
                if(v){
                    this.setState({
                        toDayValue: v
                    })
                }
            }
            else if(e == 'priceRange'){
                if(v){
                    this.setState({
                        data: {...this.state.data, "priceRange": v}
                    })
                }
            }
            else if(e == 'category'){
                if(v){
                    this.setState({
                        data: {...this.state.data, "category": v['ctry']}
                    })
                }
            }
            else if(e == 'city'){
                if(v){
                    this.setState({
                        data: {...this.state.data, "city": v['ct']}
                    })
                }
            }
            else if(e == 'sf'){


                if(v.length > 0 ){

                    this.setState({
                           data: {...this.state.data, [e]: v}
                        })

                 /*   v.map((item)=>{
                        this.setState({
                            data: {...this.state.data, sf: [...this.state.data.sf, item]}
                        })
                    }) */
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }

                    else if(e == 'featuresTags'){
                        console.log(v,"ftv")
                if(v.length > 0 ){

                       this.setState({
                           data: {...this.state.data, [e]: v}
                        })
                       /*
                    v.map((item)=>{
                        delete item["_id"]
                        this.setState({
                            data: {...this.state.data, featuresTags: [...this.state.data.featuresTags, item.ft]}
                        })
                    })*/
                    console.log(this.state.data.featuresTags, 'Chkft') 
                }
                if(v.length  == 0 ){
                    this.setState({
                        data: {...this.state.data, [e]: []}
                    })
                }
            }
            else{
                if(v){
                    this.setState({
                        data: {...this.state.data, [e]: v['ft']}
                    })
                }
            }
        }        
    }

    handleCuisineDelete=(index)=>{                
 
        this.state.data.cuisines.splice(index, 1)                
        this.setState({
            data: {...this.state.data, 'cuisines': this.state.data.cuisines}
        })
    }

    handleDPDelete=(index)=>{                
        this.state.data.delivery.splice(index, 1)        
        this.setState({
            data: {...this.state.data, "delivery": this.state.data.delivery}
        })
    }

    handleFtDelete=(index)=>{        
        this.state.data.featuresTags.splice(index, 1)
        this.setState({
            featuresTags: {...this.state.data.featuresTags, "featuresTags": this.state.data.featuresTags}
        })
    }   

    handleDpChange = (e, index) =>{
        this.state.data.delivery[index]['partnerLink'] = e.target.value
        this.setState({
            delivery: this.state.data.delivery
        })
    }

    selectTime = (key, e, value) =>{  

        console.log("Select function call")        
        if(e == 'fromTime'){
            console.log(e)
            this.state.fromDay.map((item, index)=>{
                if(item == key){
                    this.state.timings[index][key]['fromTime'] = value
                    this.setState({
                        timings: this.state.timings
                    }) 
                }
            })
        }else if(e == 'toTime'){
            this.state.fromDay.map((item, index)=>{
                if(item == key){
                    this.state.timings[index][key]['toTime'] = value
                    this.setState({
                        timings: this.state.timings
                    })
                }
            })
        }

        setTimeout(()=>{
            if(this.state.timings[0]['Monday']['fromTime'] && this.state.timings[0]['Monday']['toTime']){                 
                this.setState({
                    applyForAllTimingsButton: false
                })
            }
            else if(!this.state.timings[0]['Monday']['fromTime'] || !this.state.timings[0]['Monday']['toTime']){
                console.log("SetState", "disable button")        
                this.setState({
                    applyForAllTimingsButton: true,

                })
            }
        }, 300)
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    selectTime = (key, e, value) =>{
        if(e){
            console.log(e)
            this.state.fromDay.map((item, index)=>{
                if(item == key){
                    this.state.timings[index][key][e] = value
                    this.setState({
                        timings: this.state.timings
                    }) 
                }
            })
        }

        setTimeout(()=>{
            if(this.state.timings[0]['Monday']['fromTime'] && this.state.timings[0]['Monday']['toTime']){
                console.log("SetState", "enable button")        
                this.setState({
                    applyForAllTimingsButton: false
                })
            }
            else if(!this.state.timings[0]['Monday']['fromTime'] || !this.state.timings[0]['Monday']['toTime']){
                console.log("SetState", "disable button")        
                this.setState({
                    applyForAllTimingsButton: true,

                })
            }
        }, 300)
    }

    applyValueForAll = () => {
        const fromTime = this.state.timings[0]['Monday']['fromTime']
        const toTime = this.state.timings[0]['Monday']['toTime']
        
        this.state.fromDay.map((item, index)=>{            
            this.state.timings[index][item]['toTime'] = toTime            
            this.state.timings[index][item]['fromTime'] = fromTime
        })

        if(this.state.changeToMultipleTimings){
            const fromTime1 = this.state.timings[0]['Monday']['fromTime1']
            const toTime1 = this.state.timings[0]['Monday']['toTime1']
            
            this.state.fromDay.map((item, index)=>{            
                this.state.timings[index][item]['toTime1'] = toTime1           
                this.state.timings[index][item]['fromTime1'] = fromTime1
            })  
        }

        this.setState({
            timings: this.state.timings
        })
    }

    validateTimings = () =>{
        let numberOfFieldsRequired = 0;

        this.state.fromDay.map((item, index)=>{
            if(this.state.timings[index][item]['selected']){
                if(this.state.timings[index][item]['fromTime'] == "No"){
                    this.state.timings[index][item]['toTime'] = "No"
                }
                if(!this.state.timings[index][item]['fromTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['fromTimeError'] = true                  
                }
                else if(!this.state.timings[index][item]['toTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['toTimeError'] = true               
                }
                else {                    
                    this.state.timings[index][item]['toTimeError'] = false
                    this.state.timings[index][item]['fromTimeError'] = false 
                    this.state.timings[index][item]['toTimeError1'] = false
                    this.state.timings[index][item]['fromTimeError1'] = false
                }
            }
        })        
        this.setState({
            timings: this.state.timings,
            openSelectHours: !numberOfFieldsRequired == 0,
            validateOkay: numberOfFieldsRequired == 0,            
        })

        if(numberOfFieldsRequired == 0){
            let filterTimings = []
            this.state.fromDay.map((item, index)=>{
                if(this.state.timings[index][item]['selected']){
                    let obj = {
                        [item]: {
                            fT: this.state.timings[index][item]['fromTime'],
                            tT: this.state.timings[index][item]['toTime']
                        }
                    }
                    filterTimings.push(obj)
                }
            })    
            console.log(filterTimings)        
            this.setState({
                data: {...this.state.data, 'operatingTime': filterTimings} 
            })
        }
    }

    validateMultipleTimings = () =>{
        let numberOfFieldsRequired = 0;

        this.state.fromDay.map((item, index)=>{                     
            if(this.state.timings[index][item]['selected']){
                if(this.state.timings[index][item]['fromTime'] == "No"){
                    this.state.timings[index][item]['toTime'] = "No"
                }
                
                if(this.state.timings[index][item]['fromTime1'] == "No"){
                    this.state.timings[index][item]['toTime1'] = "No"
                }

                if(!this.state.timings[index][item]['fromTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['fromTimeError'] = true                  
                }
                if(!this.state.timings[index][item]['toTime']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['toTimeError'] = true               
                }
                
                if(!this.state.timings[index][item]['fromTime1']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['fromTimeError1'] = true                  
                }
                if(!this.state.timings[index][item]['toTime1']){
                    numberOfFieldsRequired = numberOfFieldsRequired + 1
                    this.state.timings[index][item]['toTimeError1'] = true                  
                }
                else {
                    this.state.timings[index][item]['toTimeError'] = false
                    this.state.timings[index][item]['fromTimeError'] = false 
                    this.state.timings[index][item]['toTimeError1'] = false
                    this.state.timings[index][item]['fromTimeError1'] = false
                }
            }
        })                
        
        this.setState({
            timings: this.state.timings,
            openSelectHours: !numberOfFieldsRequired == 0,
            validateOkay: numberOfFieldsRequired == 0,            
        })

        if(numberOfFieldsRequired == 0){
            let filterTimings = []
            this.state.fromDay.map((item, index)=>{
                if(this.state.timings[index][item]['selected']){
                    let obj = {
                        [item]: {
                            fT: this.state.timings[index][item]['fromTime'],
                            tT: this.state.timings[index][item]['toTime'],
                            fT1: this.state.timings[index][item]['fromTime1'],
                            tT1: this.state.timings[index][item]['toTime1']
                        }
                    }
                    filterTimings.push(obj)
                }
            })            
            this.setState({
                data: {...this.state.data, 'operatingTime': filterTimings} 
            })
        }
    }

    handleDayTimingRemove = (index, item) =>{
        this.state.timings[index][item]['selected'] = !this.state.timings[index][item]['selected'] 
        this.setState({ timings: this.state.timings })
    }

    render(){                
        const { data, image, crop, openImageCrop, imageUploading, loading, openSelectHours, applyForAllTimingsButton } = this.state;                
        console.log(data['sf'], 'Chk')
        return(
            <div style={{width: "100%"}}>
            {loading ? 
                <div style={{width: "100%", height: "90vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                    <CircularProgress/>
                </div> :            
                
            <div style={{width: "100%", display: 'flex', flexDirection: "column" }}>
                <Typography style={{ margin: 10}} variant='h5'>Edit restaurant details</Typography>
                <form
                    ref="form"
                    onSubmit={this.uploadEditedData}
                >             
                <div style={{display: 'flex', width: "100%", flexDirection: 'column', flexWrap: 'wrap' }} >
                    {formFields.map((item, index)=>
                        <div style={{display: 'flex', width: "100%", flexDirection: 'column', flexWrap: 'wrap' }} >
                            
                       
                        

                                   {index == 1 && <div style={{width: "100%", margin: 10, display: 'flex'}} >
                          

                                 <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                  <Typography style={{marginBottom: 5}}>Category *</Typography>
                                        <Autocomplete
                                            options={this.state.categories} 
                                            id="categories"
                                            defaultValue={{ctry: data.category}}
                                            onChange={(e, value) => this.handleAutoComplete("category", value)}
                                            getOptionLabel={option => option['ctry']}                                                                                                                                                                   
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />

                                </div>


                                   

                                      <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>

                                       <Typography style={{marginBottom: 5}}>Cuisines *</Typography>
                                            <Autocomplete     
                                            multiple                                           
                                                id="cuisines"
                                              //org  options={this.state.cuisinesMenu}                            
                                              options={this.state.cuisinesMenuTitles}
                                                //org getOptionLabel={option => option.title}  
                                                getOptionLabel={option => option}  
                                                 //  defaultValue={data.cuisines} 

                                                   value={data.cuisines}                         
                                                onChange={(e, value) => this.handleAutoComplete("cuisines", value)}    
                                                filterSelectedOptions                                                
                                                renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    
                                                    placeholder="Select cuisines"
                                                />
                                            
                                                )}
                                            />
                                            {
                                              //  console.log(this.state.cuisinesMenu,"cfull")}{
                                              //  console.log(this.state.data.cuisines,"cus")
                                            }
                                     {  /*  <div style={{display: 'flex', flexWrap: 'wrap', width: 300}} >
                                                {
                                                    data.cuisines.length > 1 ? 
                                                        data.cuisines.map((item, index) => 
                                                            <Chip label={item} style={{margin: "10px 0px", marginRight: 10}} onDelete={()=> this.handleCuisineDelete(index)}/>
                                                        ) :
                                                        data.cuisines.map((item, index) => 
                                                            <Chip style={{margin: "10px 0px"}} label={item}/>
                                                        )
                                                }
                                            </div> */}

                                </div>


                                 <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                 <Typography style={{marginBottom: 5}}>Price range *</Typography>
                                        <Autocomplete
                                            options={this.state.priceRanges} 
                                            id="priceRange"
                                            onChange={(e, value) => this.handleAutoComplete("priceRange", value)}
                                            getOptionLabel={option => option}
                                            defaultValue={data.priceRange}                                                                                                                                        
                                            renderInput={params => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Eg Affordable"
                                                />
                                            )}
                                        />

                                </div>
</div>
                            }
                            
                            {index == 1 && <div style={{width: "100%", margin: 10, display: 'flex'}} >
                                <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 2}}>
                                    <Typography style={{marginBottom: 5}}>Area *</Typography>
                                    <Autocomplete
                                        options={this.state.area} 
                                        id="area"
                                        defaultValue={{value: data.area}}
                                        onChange={(e, value) => this.handleAutoComplete("area", value)}
                                        getOptionLabel={option => option.value}                                                                                    
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>                                                
                                    <Typography style={{marginBottom: 5}}>City</Typography>
                                    <Autocomplete
                                        options={this.state.cities} 
                                        id="city"
                                        onChange={(e, value) => this.handleAutoComplete("city", value)}
                                        defaultValue={{ct: data.city}}
                                        getOptionLabel={option => option['ct']}                                                    
                                        renderInput={params =>(<TextField required {...params} variant="outlined" placeholder="Eg Chennai" />)}
                                    />
                                </div>
                            </div>
                        }
                            {index == 4 && <Typography style={{ margin: "10px 10px 5px 10px" }} > Description</Typography> }
                            {index == 4 ? 
                                <div style={{width: "100%", margin: 10, display: 'flex', flexDirection: 'column'}} >
                                    <div style={{paddingLeft: 10, border: "1px solid rgba(0,0,0,0.2)", borderRadius: 5, height: 300 }} >
                                        <MUIRichTextEditor
                                            toolbarButtonSize="medium"
                                            controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote"]}
                                            label="Describe"
                                            onChange={(state)=> this.setState({_editorState: state})}
                                            value={this.state.contentData}
                                            inlineToolbar={false}                                            
                                        />                                         
                                    </div>
                                </div>
                                : ''
                            }

                            {index == 4 &&
                                <div style={{width: "100%", display: 'flex', alignItems: 'center', marginBottom: 5}} >                                             
                                    <Typography style={{ margin: "10px" }} >Opening hours *</Typography> 
                                    <div><Button variant="outlined" size='small' color={ this.state.validateOkay ? 'primary': 'secondary'} onClick={()=> this.setState({
                                        openSelectHours: true
                                    })} >Select hours</Button></div>
                                </div>
                            }
                            {index == 4 &&
                                <div style={{width: "100%", marginLeft: 10, display: 'flex', flexWrap: 'wrap', marginBottom: 10}} >                                            
                                {this.state.validateOkay && this.state.timings.map((item, index)=>
                                    Object.keys(item).map((keyName, index)=>
                                        item[keyName]['selected'] && <Chip label={item[keyName]['fromTime1'] ? keyName+' '+'FN'+" "+item[keyName]['fromTime']+" "+'to'+" "+item[keyName]['toTime']+" "+'AN'+" "+ item[keyName]['fromTime1']+" "+"to"+" "+item[keyName]['toTime1'] : keyName+' '+item[keyName]['fromTime']+" "+'to'+" "+item[keyName]['toTime']} style={{marginRight: 10, marginBottom: 10}}/>
                                    )                                            
                                )}                                                                                        
                                </div>
                            }                        

                            {item.parallel ?
                                <div style={{width: "100%", margin: 10, display: 'flex'}} >
                                    <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                        <Typography style={{marginBottom: 5}}>{item.label} {item.required && "*"}</Typography>
                                        <TextField
                                            placeholder={item.placeholder}
                                            key={index}
                                            required={item.required}
                                            onChange={this.handleChange}
                                            multiline={item.multiline}
                                            rows={item.noOfRows}
                                            variant="outlined"
                                            type={item.type}
                                            name={item.name}
                                            value={ item.name == 'youtube_url' ? this.state.data[item.name] ? "https://www.youtube.com/watch?v="+this.state.data[item.name] : '' : this.state.data[item.name]}
                                        />
                                    </div>
                                    <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                        <Typography style={{marginBottom: 5}} >{item.label1} {item.required && "*"}</Typography>
                                        <TextField
                                            placeholder={item.placeholder1}
                                            style={{flex: 1}}
                                            key={index}
                                            required={item.required1}
                                            // label={item.label1}
                                            onChange={this.handleChange}
                                            multiline={item.multiline1}
                                            rows={item.noOfRows1}
                                            variant="outlined"
                                            type={item.type1}
                                            name={item.name1}
                                            value={this.state.data[item.name1]}
                                        />
                                    </div>
                                </div> : 
                                <div style={{width: "100%", margin: 10, display: 'flex'}} >
                                    <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
                                        <Typography style={{marginBottom: 5}}>{item.label} {item.required && "*"}</Typography>
                                        <TextField
                                            placeholder={item.placeholder}
                                            key={index}
                                            required={item.required}
                                            onChange={this.handleChange}
                                            multiline={item.multiline}
                                            rows={item.noOfRows}
                                            variant="outlined"
                                            type={item.type}
                                            name={item.name}
                                            value={this.state.data[item.name]}
                                        />
                                    </div>
                                </div>
                            }
                                    {/* {index == 6 ? <Button style={{margin: 10, width: 300}} variant="outlined" color="primary" onClick={()=> this.setState({ editDescription: true })} >Edit description</Button> : ""} */}

                                   
                                    {index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 5}}>Special foods *</Typography>
                                            <Autocomplete
                                                multiple
                                                id="sf"
                                                options={this.state.sflist}
                                                getOptionLabel={option => option}
                                                defaultValue={data.sf}
                                                onChange={(e, value) => this.handleAutoComplete("sf", value)}
                                                filterSelectedOptions                            
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Eg: Shawarma, Tandoori, Tacos"
                                                    />
                                                )}
                                            />
                                        </div>
                                    }


                                          { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 5}}>Features & tags</Typography> 
                                            <Autocomplete
                                            multiple
                                                id="ft"
                                                defaultValue={data.featuresTags}
                                                options={this.state.ft}      
                                               // defaultValue={{ ft: data.featuresTags }}                      
                                                getOptionLabel={option => option}
                                                onChange={(e, value) => this.handleAutoComplete("featuresTags", value)}                        
                                                renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select Feature & tags"
                                                />
                                                )}
                                            />
                                        </div>
                                    }

                                    {index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 5}}>Delivery partners</Typography>
                                            <Autocomplete                                                
                                                id="dp"
                                                options={this.state.deliveryPartners}                            
                                                getOptionLabel={option => option.deliveryPartnerName}                            
                                                onChange={(e, value) => this.handleAutoComplete("dp", value)}                                         
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Delivery partners"
                                                    />
                                                )}
                                            />
                                            <div style={{display: 'flex', flexWrap: 'wrap', width: "100%"}} >
                                            {
                                                data.delivery == '' ? "" : 
                                                    data.delivery.map((item, index) => 
                                                        <Chip 
                                                            label={item.partnerName} 
                                                            style={{margin: "10px 0px", marginRight: 10}} 
                                                            onDelete={()=> this.handleDPDelete(index)}
                                                        />
                                                    )
                                            }
                                            </div>
                                        </div>
                                    }
                                    { index == 7 ? data.delivery == '' ? '' : data.delivery.map((item, i) =>                 
                                            <div style={{display: 'flex', width: "100%", flexDirection: 'column', flexWrap: 'wrap' }} >                        
                                                <TextField
                                                    style={{margin: 10, width: "100%"}}
                                                    key={i}
                                                    label={item.partnerName+" "+"link"}
                                                    onChange={(e)=> this.handleDpChange(e, i)}
                                                    variant="outlined"
                                                    type={item.type}
                                                    name={item.name}
                                                    value={this.state.data.delivery[i]['partnerLink']}                            
                                                />                
                                            </div>
                                        ) : ''
                                    }
                              
                                    { index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 10}}>Featured image </Typography>
                                            { data.featured_image == '' ? 
                                            <div>
                                                <Button   
                                                    size="small" 
                                                    color="primary"
                                                    variant="outlined" 
                                                    onClick={()=> document.getElementById('featured_image').click()}
                                                >
                                                    Select image
                                                </Button></div> :
                                                <Card style={{width: 280, boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.4)"}}>                                                                                            
                                                    <img src={ data.featured_image.length == 32 ? prefixImageUrl+data.featured_image+'.jpg' : data.featured_image.length == 36 ? prefixImageUrl+data.featured_image : data.featured_image } style={{width: 280, height: 300, objectFit: 'contain'}}/>
                                                    <CardActions>
                                                        <div style={{display: 'flex', flex: 1}}>                                
                                                            <Button  size="small" color="secondary" variant="outlined" onClick={()=> document.getElementById('featured_image').click()}>
                                                                Change
                                                            </Button>
                                                        </div>
                                                    </CardActions>
                                                </Card>                                                
                                            }
                                        </div>
                                    }
                                    {index == 7 && 
                                        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 15px 10px 10px', flex: 1}}>
                                            <Typography style={{marginBottom: 10}}>Gallery images</Typography>
                                            <div>                        
                                                <Button  size="small" style={{marginLeft: 0}} color="primary" variant="outlined" onClick={()=>this.handleEditImage(true)}>
                                                    Gallery images
                                                </Button>
                                            </div>
                                        </div>
                                    }
                        </div>
                    )}                    
                    </div>

                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} >

                <div>   
                    <input
                        hidden
                        type="file"
                        style={{padding: 10, margin: 10,}}                        
                        id="featured_image"
                        accept="image/png, image/jpeg" 
                        name="featured_image"
                        onChange={this.handleImageChange}
                    />                    

                    <input id='galleryImage' hidden multiple type="file" accept="image/png, image/jpeg" name="galleryImage" onChange={(e)=>this.fileSelectHandler(e,false)} />                        
                    </div>
                    <div style={{ width: "100%" , display: 'flex'}}>                        
                        <div  style={{display: 'flex', flex: 1,margin: 20, justifyContent: 'flex-end'}}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"                                
                            >
                                Update details
                            </Button>
                        </div>
                    </div>
                    </div>
                </form>

                <Dialog maxWidth  open={openImageCrop} >
                    <DialogTitle>Resize image</DialogTitle>
                    <DialogContent style={{ minHeight: 600 }}>                     
                    <ReactCrop
                        style={{ minHeight: 600, width: this.state.openGalleryImageDialog ? 850 : 600 }}
                        src={image}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='secondary' onClick={this.uploadCropedImage} >
                            {imageUploading ? "Uploading" : "Save"}
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openImageCrop: false })} >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <input
                    hidden
                    type="file"
                    style={{padding: 10, margin: 10,}}                        
                    id="gallery_image"
                    accept="image/png, image/jpeg" 
                    name="gallery_image"
                    onChange={this.handleImageChange}
                />

                <Dialog maxWidth open={this.state.openGalleryImageDialog} onClose={()=> this.setState({ openGalleryImageDialog: false })} >
                <DialogTitle>Gallery images * three images minimum required</DialogTitle>
                    <DialogContent style={{ minHeight: 400, width: 1075, display: 'flex', flexWrap: 'wrap' }}>
                        {
                            data.gallery_images && data.gallery_images.map((item, i)=>
                                <Card style={{width: 300, margin: "0px 10px", boxShadow: 'none' }} >
                                    <img src={item.length == 32 ? prefixImageUrl+item+'.jpg' : item.length == 36 ? prefixImageUrl+item : item } style={{ height: 170, width: 300,  objectFit: 'cover' }} />
                                    <CardActions>                                                                        
                                        <Button variant='outlined'  size="small" color="secondary" onClick={ ()=>this.deleteImag(i)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        }                    

                    <Button style={{ height: 170, width: 300, margin: 10, display: 'flex',  justifyContent: 'center', alignItems: 'center' }} onClick={()=> document.getElementById('gallery_image').click()}  variant='outlined' >
                         <div> <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} > <AddIcon/> Add new image </div> </div> 
                    </Button>
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openGalleryImageDialog: false })} >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth  open={openSelectHours}>
                    <DialogTitle>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', }}> 
                            <Typography component='h5' variant='h5' >Select operating hours *</Typography>
                            <div>
                                <FormControlLabel
                                    label="Multiple timings"
                                    control={
                                        <Switch 
                                            checked={this.state.changeToMultipleTimings} 
                                            onChange={()=> this.setState({ changeToMultipleTimings:  !this.state.changeToMultipleTimings })} 
                                            name="checkedA"
                                        />
                                    }
                                />
                                <Button variant='outlined' color="primary" size='small' disabled={applyForAllTimingsButton} onClick={this.applyValueForAll} >Apply for all</Button>
                            </div>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{ minHeight: 400, minWidth: 900}}>                        
                            { this.state.changeToMultipleTimings && <div style={{display: 'flex'}} >
                                <Typography style={{width: 160}} >{""}</Typography>
                                <Typography style={{ flex: 1 }} align="center" >Morning</Typography>
                                <Typography style={{ flex: 1 }} align="center" >After noon</Typography>
                            </div>}
                            {this.state.fromDay.map((item, index)=>
                                <div style={{width: "100%", margin: 10, display: 'flex', alignItems: 'center'}} >                                    
                                    <Typography style={{width: 100}} >{item}</Typography>
                                    <Checkbox
                                        checked={this.state.timings[index][item]['selected']}
                                        color="primary"
                                        onChange={()=> this.handleDayTimingRemove(index, item)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <Autocomplete
                                        options={this.state.formTimeMenu}
                                        id={item+"FromTime"}
                                        value={this.state.timings[index][item]['fromTime']}
                                        onChange={(e, value) => this.selectTime(item, "fromTime", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 5 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                error={this.state.timings[index][item]['fromTimeError']}
                                                {...params}
                                                variant="outlined"
                                                placeholder="10:00 AM"
                                            />
                                        )}
                                    />
                                   <Autocomplete
                                        options={this.state.formTimeMenu} 
                                        id={item+"ToTime"}
                                        value={this.state.timings[index][item]['toTime']}
                                        onChange={(e, value) => this.selectTime(item, "toTime", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 20 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={this.state.timings[index][item]['toTimeError']}
                                                placeholder="11:00PM"
                                            />
                                        )}
                                    />       
                                    { this.state.changeToMultipleTimings && <Autocomplete
                                        options={this.state.formTimeMenu}
                                        id={item+"FromTime1"}
                                        value={this.state.timings[index][item]['fromTime1']}
                                        onChange={(e, value) => this.selectTime(item, "fromTime1", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 5 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                error={this.state.timings[index][item]['fromTimeError']}
                                                {...params}
                                                variant="outlined"
                                                placeholder="10:00 AM"
                                            />
                                        )}
                                    />}
                                   { this.state.changeToMultipleTimings && <Autocomplete
                                        options={this.state.formTimeMenu} 
                                        id={item+"ToTime1"}
                                        value={this.state.timings[index][item]['toTime1']}
                                        onChange={(e, value) => this.selectTime(item, "toTime1", value)}
                                        getOptionLabel={option => option}
                                        style={{flex: 1, marginRight: 5 }}
                                        disabled={!this.state.timings[index][item]['selected']}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={this.state.timings[index][item]['toTimeError']}
                                                placeholder="11:00PM"
                                            />
                                        )}
                                    />}
                                </div>
                            )}                        
                    </DialogContent>                    
                    <DialogActions>
                        <Button variant='outlined' size='small' color='secondary' onClick={()=> this.state.changeToMultipleTimings ? this.validateMultipleTimings() : this.validateTimings()} >
                            Save
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openSelectHours: false })}  >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>}
            </div>
        )
    }
}

export default withRouter(Restaurent);