import React, { Component } from 'react'
import MaterialTable, {MTableToolbar} from 'material-table'
import { Button, Typography, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import { url, produrl, prefixImageUrl } from './constant'
import Alert from './Alert'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

class Delivery extends Component{
    constructor(){
        super();
        this.state = {
            columns:[                                
                {
                    title: "Logo",
                    align: "center",
                    render: rowData => 
                        <div>
                            <img src={rowData.deliveryPartnerLogo.length >60 ? prefixImageUrl+rowData.deliveryPartnerLogo.split(':')[2].split("/")[2]+'.jpg' : prefixImageUrl+rowData.deliveryPartnerLogo} style={{width: 100, height: 100, borderRadius: '4%', objectFit: 'contain'}}/>                            
                        </div>
                    
                },
                {
                    field: 'deliveryPartnerName',
                    title : "Delivery Partner name", 
                    align: 'center'
                },
                {
                    title: "Actions",
                    align: 'center',
                    render: rowData =>                         
                        <div >
                            <Button onClick={()=> this.editData(rowData)} ><EditIcon/></Button>
                            <Button onClick={()=> this.deleteData(rowData)} ><DeleteIcon/></Button>
                        </div>
                    
                },
            ],
            data: [],
            deliveryPartnerData: {
                deliveryPartnerName: "",
                deliveryPartnerLogo: ""
            },
            buttonDisable: true
        }
    }

    componentDidMount(){
        this.getdata()
    }

    getdata=()=>{
        fetch(url+"/getDeliveryPartners",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    data: data['response']
                })
            }        
        })
    }

    addNew = (e, edit) =>{        
        e.preventDefault()        
        if(!edit){            
                fetch(url+"/addDeliveryPartners",{
                    method: "POST",
                    body: JSON.stringify({"title": this.state.deliveryPartnerData.deliveryPartnerName, "data": this.state.deliveryPartnerData})
                })
                .then(res => res.json())
                .then((data)=>{
                    if(data['status']=='okay' && data['response']=='added'){                
                        this.getdata()
                        this.setState({        
                            setSnack:true,
                            message:'Delivery partner added successfully',
                            msgstatus:"success"
                        })
                        this.openDialog("isDialogOpen")
                    } else if(data['status']=='okay' && data['response']=='exist'){
                        this.setState({        
                            setSnack:true,
                            message:'Delivery partner already exist',
                            msgstatus:"error"
                        })
                        this.openDialog("isDialogOpen")
                    }
                })                   
        }else if(edit){
            let id = this.state.id    
               
            delete this.state.deliveryPartnerData["_id"] 
            let temp = this.state.data.filter((item)=> item.deliveryPartnerName == this.state.deliveryPartnerData.deliveryPartnerName)
            console.log(temp)
            if(temp.length == 0){        
                fetch(url+"/editDeliveryPartners",{
                    method: "POST",
                    body: JSON.stringify({ id: id, data: this.state.deliveryPartnerData })
                })
                .then(res => res.json())
                .then((data)=>{
                    if(data['status']=='okay'){
                        this.setState({        
                            setSnack:true,
                            message:'Edited successfully',
                            msgstatus:"success"
                        })
                        this.openDialog("isDialogOpen")
                        this.getdata()
                    }                    
                })
            }
            else{
                this.setState({        
                    setSnack:true,
                    message:'Delivery partner already exist',
                    msgstatus:"error"
                })
            }
        }
    }

    editData = (newData) =>{
        this.setState({
            isDialogOpen: true,
            edit: true,
            id: newData["_id"]["$oid"],
            deliveryPartnerData: newData
        })                
    }

    deleteData = (newData) =>{
        fetch(url+"/deleteDeliveryPartners",{
            method: "POST",
            body: JSON.stringify({"id": newData['_id']['$oid']})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({        
                    setSnack:true,
                    message:'Deleted successfully',
                    msgstatus:"success"
                })
                this.getdata()
            }        
        })
    }

    openDialog =(key)=>{
        this.setState({
            [key]: !this.state[key]
        })
    }

    handleImageChange = (e) =>{
        console.log(Array.from(e.target.files))
        if(e.target.files){
            this.setState({
                image: Array.from(e.target.files)        
            })
            let form_data = new FormData();    
    
            Array.from(e.target.files).map((item, index)=>{
                form_data.append('image', item);
                fetch(produrl+"/getImage",{
                    method: "POST",
                    body: form_data
                })
                .then((res)=> res.json())
                .then((data)=>{ 
                        console.log(data)                                       
                    this.setState({
                        deliveryPartnerData: {...this.state.deliveryPartnerData, "deliveryPartnerLogo": data['data']},
                        buttonDisable: false                
                    })
                })
            });
        }
    }

    handleTextChange = (e) =>{
        this.setState({
            deliveryPartnerData: {...this.state.deliveryPartnerData, [e.target.name]: e.target.value}
        })
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    render(){
        const { columns, data, buttonDisable } = this.state
        return(
            <div style={{width: "100%"}} >
                <MaterialTable 
                    columns={columns} 
                    data={data} 
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10,
                        actionsColumnIndex: -1
                    }}
                    title="Manage delivery partners"                    
                    components={{
                        Toolbar: props => (
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }}><MTableToolbar style={{ flex: 12 }} {...props} /></div>

                                <div style={{ padding: 4, alignSelf: 'center' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Button variant='outlined' onClick={()=> this.openDialog("isDialogOpen")} style={{marginRight: 5}}>
                                            Add_New
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />

                    <Dialog fullWidth open={this.state.isDialogOpen}  onClose={()=>this.openDialog("isDialogOpen")} aria-labelledby="addPromoForm">
                        <DialogTitle id="addPromoForm">Add / Edit Delivery Partner</DialogTitle>
                        <form onSubmit={(e)=> this.addNew(e, this.state.edit)}>
                        <DialogContent>                                                
                        <TextField
                            style={{margin: '10px 0px'}}
                            autoFocus
                            required
                            name="deliveryPartnerName"
                            id="deliveryPartnerName"
                            label="Delivery partner name"
                            type="text"
                            variant='outlined'
                            fullWidth
                            value={this.state.deliveryPartnerData.deliveryPartnerName}                            
                            onChange={this.handleTextChange}                 
                        />
                        <Typography style={{margin: "10px 0px"}}>Logo</Typography>
                        <input
                            type="file"
                            style={{padding: 10, margin: 0,}}
                           required={!this.state.edit}
                           // required="false"
                            id="image"
                            accept="image/png, image/jpeg" 
                            name="promoImage"
                            onChange={this.handleImageChange}                
                        /> 
                        </DialogContent>                        
                        <DialogActions>
                        <Button onClick={()=>this.openDialog("isDialogOpen")} variant="outlined" color="primary" >
                            Cancel
                        </Button>
                        <Button type='submit' color="secondary"  variant="outlined" >
                            Save
                        </Button>
                        </DialogActions>
                        </form>
                    </Dialog>
                    <Snackbar
                        open={this.state.setSnack}
                        autoHideDuration={2000}
                        onClose={this.handleClose}
                    >
                        <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                            {this.state.message}
                        </Alert>
                    </Snackbar>
            </div>
        )
    }
}

export default Delivery;