import React from 'react'
import MaterialTable from 'material-table';
import { Snackbar } from '@material-ui/core'
import { url } from './constant'
import loadsh from 'loadsh'

import Alert from './Alert'

class PromoTypes extends React.Component{
    constructor(){
        super()
        this.state = {
            columns: [
                {
                    title: "Field name",
                    field: "fName"
                }
            ],
            data: [],
            loading: true
        }
    }

    componentDidMount(){        
        this.getdata()
    }

    getdata=()=>{
        this.setState({
            loading: true
        })
        fetch(url+"/getPromoType",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    data: data['response'],
                    loading: false
                })                
            }        
        })
        .catch((er)=>{
            this.setState({                  
                loading: false
            })
        })
    }

    addNew = (newData) =>{      
        newData.fValue = loadsh.camelCase(newData.fName) 
        if(this.state.data.filter(item => item.fValue == newData.fValue).length == 0){
            delete newData.tableData        
            fetch(url+"/addPromoType",{
                method: "POST",
                body: JSON.stringify({data: newData, fValue: newData.fValue})
            })
            .then(res => res.json())
            .then((data)=>{
                if(data['status']=='okay' && data['response']=='added'){                
                    this.setState({        
                        setSnack:true,
                        message:'New promotion type added',
                        msgstatus:"success"
                    })
                    this.getdata()
                }
            })
        }
        else {
            this.setState({        
                setSnack:true,
                message:'Promotion type already exist',
                msgstatus:"error"
            })
        }       
    }

    editData = (newData, oldData) =>{
        let id = newData['_id']['$oid']
        delete newData['_id']
        
        if(newData.fName!=''){
            if(JSON.stringify(newData['fName'])!=JSON.stringify(oldData['fName'])){
                fetch(url+"/editPromoType",{
                    method: "POST",
                    body: JSON.stringify({ id: id, data: newData})
                })
                .then(res => res.json())
                .then((data)=>{
                    if(data['status']=='okay'){
                        this.setState({        
                            setSnack:true,
                            message:'Promotion type edited successfully',
                            msgstatus:"success"
                        })
                        this.getdata()
                    }        
                })
            }
        }
        else{
            this.setState({        
                setSnack:true,
                message:'Promotion type should not be empty',
                msgstatus:"error"
            })
        }
    }

    deleteData = (newData) =>{
        fetch(url+"/deletePromoType",{
            method: "POST",
            body: JSON.stringify({"id": newData['_id']['$oid']})
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.getdata()
                this.setState({        
                    setSnack:true,
                    message:'Promotion type successfully',
                    msgstatus:"success"
                })
            }        
        })
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    render(){
        const { columns, data, loading } = this.state
        return(
            <div style={{width: "100%"}}>            
                <MaterialTable 
                    columns={columns} 
                    data={data} 
                    isLoading={loading}                    
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10,
                        actionsColumnIndex: -1
                    }}
                    title="Promo types"                    
                    editable ={{                      
                        onRowUpdate: (newdata, olddata) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.editData(newdata, olddata)
                                    resolve();
                                }, 10);
                            }),                        
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.addNew(newData)                                                        
                                    resolve();
                                }, 100)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {                    
                                    this.deleteData(oldData)
                                    resolve();
                                }, 100);
                            })
                        }}
                />
                <Snackbar
                    open={this.state.setSnack}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default PromoTypes