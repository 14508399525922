import React from 'react'
import {Button, Card, Typography, TextField, IconButton, Switch, FormControlLabel, DialogTitle, Dialog, CircularProgress, DialogActions, CardContent, CardActions, CardMedia} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { url } from './constant'

import store from 'store';


import MaterialTable, { MTableToolbar } from 'material-table';
import { Search } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const headerColor = 'aliceblue'
class ViewRes extends React.Component{
    constructor(props){
        super(props);
        this.state={
            columns: [
                {
                    title: "Enabled",
                    align: "center",
                    render: rowData => 
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={!rowData.isDisabled} 
                                    onChange={()=>this.enableRdisable(rowData)} 
                                    name="checkedA"
                                    disabled={!store.get("log_in_userAdmin")}
                                />
                            }
                        />
                },
                {
                    title: "Added By",
                    field: "addedby",
                    align: "center"
                },
                {
                    title: "Restaurant name",
                    field: "name",
                    align: "left"
                },
                {
                    title: "Price range",
                    align: "center",
                    render: rowData => 
                            <ToggleButtonGroup
                                value={rowData.priceRange}
                                exclusive
                                onChange={(e, value)=>this.updatePriceRange(rowData, e, value)}
                                aria-label="text alignment"
                                size='small'
                                disabled={!store.get("log_in_userAdmin")}
                            >                                
                                <ToggleButton value="Affordable" aria-label="left aligned" disabled={!store.get("log_in_userAdmin")}>
                                    Affordable
                                </ToggleButton>
                                <ToggleButton value="Mid range" aria-label="centered" disabled={!store.get("log_in_userAdmin")}>
                                    Mid_range
                                </ToggleButton>
                                <ToggleButton value="High end" aria-label="right aligned" disabled={!store.get("log_in_userAdmin")}>
                                    High_end
                                </ToggleButton>
                            </ToggleButtonGroup>
                },
                {
                    title: "Featured status",
                    align: "center",
                    render: rowData => 
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={rowData.featured} 
                                    onChange={()=>this.changeFeature(rowData)} 
                                    name="checkedA"
                                    disabled={!store.get("log_in_userAdmin")}
                                />
                            }
                        />
                },
                {
                    title: "Cuisines",
                    render: rowData => rowData.cuisines.map(item => <div>{item}</div>),                
                    align: "center"
                },
                {
                    title: "Area",
                    field: "area",
                    align: "center"
                },
                 
                {
                    title: "Actions",
                    align: "center",
                    render: rowData => 
                        <div style={{display: 'flex', width: "100%", justifyContent: "space-evenly" }} >
                           {store.get("log_in_userAdmin") &&  <EditIcon  disabled={!store.get("log_in_userAdmin")} color="primary" onClick={()=> this.props.history.push({pathname: '/restaurant', state: {data: rowData}})} />
                            }
                            {store.get("log_in_userAdmin") &&  <DeleteIcon  disabled={!store.get("log_in_userAdmin")} color="secondary" onClick={()=> this.setState({openDialog: true, deleteData: rowData})} />
                            }
                        </div>
                },
            ],
            data: [],
            reqdata:{},
            openDialog: false,
            deleteData: {},
            filterData: {},
            loading: false,
            cuisines: '',
            area: '',
            limit: 20,
            dataFetch: [],
            skip: 0
        }
    }

    updatePriceRange  = (d, e, newAlignment) =>{
        let data = this.state.data;        
        let id = d["_id"]["$oid"]        
        
        fetch(url+"/editPriceRange", {
            method:"POST",
            body: JSON.stringify({
                "id": id,
                "priceRange": newAlignment
            })
        })
        .then(res => res.json())
        .then((resData)=>{
            if(resData["status"]== "okay"){
                let index = data.findIndex((item) => item["_id"]["$oid"]==id );
                let item = data[index]

                item.priceRange = newAlignment
                data[index] = item
                this.setState({ data })
            }
        })
    }

    enableRdisable =(e)=>{
        let data = this.state.data;        
        let id = e["_id"]["$oid"]        
        fetch(url+"/enableRdisable", {
            method:"POST",
            body: JSON.stringify({
                "id": id,
                "isDisabled": !e["isDisabled"]
            })
        })
        .then(res => res.json())
        .then((resData)=>{
            if(resData["status"]== "okay"){
                let index = data.findIndex((item) => item["_id"]["$oid"]==id );
                let item = data[index]
                item.isDisabled = !data[index].isDisabled
                data[index] = item
                this.setState({ data })
            }
        })
    }

    changeFeature =(e)=>{
        let data = this.state.data;        
        let id = e["_id"]["$oid"]        
        fetch(url+"/editFeatureTypeRes", {
            method:"POST",
            body: JSON.stringify({
                "id": id,
                "isFeatured": !e["featured"]
            })
        })
        .then(res => res.json())
        .then((resData)=>{
            if(resData["status"]== "okay"){
                let index = data.findIndex((item) => item["_id"]["$oid"]==id );
                let item = data[index]
                item.featured = !data[index].featured
                data[index] = item
                this.setState({ data })
            }
        })
    }

    componentDidMount(){
        fetch(url+"/getCuisine", {
            method: "GET"
        })
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                cuisines: data['response']
            })
        })

        fetch(url+"/getArea", {
            method: "GET"
        })
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                area: data['response']
            })
        })
        this.handleSearchSubmit(200, 0, false)
    }

    handleAutoComplete = (e, v)=>{
        this.setState({
            filterData: {...this.state.filterData, [e] : v}         
        })
    }

    deleteRestaurant(oldData){
        fetch(url+"/deleteRestaurant",{
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({"_id": oldData['_id']['$oid']})
        })
        .then((res)=> res.json())
        .then((data)=>{            
            if(data["status"]==="okay"){
                this.setState({ openDialog: false })
                window.location.reload()
            }
        })
    }

    handleSearchSubmit = async (limit, skip, frmOnClr) =>{
        this.setState({
            loading: true
        })        
        if(frmOnClr){         
            this.setState({
                data: []              
            })       
        }          

        console.log(this.props,"props")

        if(this.props.location["pathname"]=="/myrestaurants")
        {
            this.reqdata={"addedby": store.get('log_in_user')}
        }
        else
        {
            this.reqdata={}
        }



        await fetch(url+'/getrestaurants1', {
            method: "POST",
            body: JSON.stringify({"data":this.reqdata, "skip": skip, "limit": limit})
        })
        .then(res =>res.json())
        .then((data)=>{                        
            this.setState({
                resLength: data['length'],
                data: this.state.data.concat(data['response']),              
            })
            setTimeout(()=>{
                this.setState({
                    loading: false
                })
            }, 1000)
            
        })
        .catch((err) =>{
            this.setState({
                loading: false
            })
        })
    }

    fetchRestaurant = e => {        
        if(e){
            this.setState({
                dataFetch: []
            })
            fetch(url+'/fetchRestaurantAutoComplete',{
                method: "POST",
                body: JSON.stringify({"name": {"$regex": e.target.value, $options:'i'}})
            })
            .then(res=> res.json())
            .then((data)=>{
                if(data['status'] == "okay"){   
                    this.setState({
                        dataFetch: data['response']
                    })
                }
            })
        }
    }

    handleOnSelect = (v)=>{        
        if(v){
            const data = [v]
            this.state.data = []
            this.setState({
                data: data,
                loading: true           
            })
            setTimeout(()=>{
                this.setState({
                    loading: false,                    
                })
            }, 2000)
        }
    }

    render(){
        const { openDialog, deleteData, loading, columns, data } = this.state;  
        console.log(this.state.data)                    
        return(
            <div style={{ width: "100%" }}>            
                <Typography variant='h5' component='h5' gutterBottom>Restaurants</Typography>
                <Autocomplete
                    style={{width: "100%", margin: "20px 0px"}}                            
                    options={this.state.dataFetch}
                    closeIcon={ <ClearIcon onClick={()=> window.location.reload() }/>}
                    id="area"
                    onChange={(e, value) => this.handleOnSelect(value)}
                    getOptionLabel={option => option.name}
                    value=''                             
                    renderInput={params => (
                    <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Select restaurant"
                        placeholder="Select restaurant"
                        onChange={this.fetchRestaurant}
                    />
                    )}
                />  
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <MaterialTable                
                        style={{width: "100%", marginTop: 20}}
                        title="All restaurants"
                        isLoading={loading}                            
                        columns={columns}                     
                        options = {{
                            search: false,
                            showTitle: false,
                            paging: true,
                            toolbar: false,
                            pageSize: 10,
                            pageSizeOptions: [10,50,100]
                        }}                    
                        data={data} 
                        onChangePage={(page)=>
                            (10 * page) + 10 == data.length ? this.handleSearchSubmit(200, (10 * page) + 10, false) : '' 
                        }
                    />
                </div>
                <Dialog
                    open={openDialog}
                    onClose={()=> this.setState({openDialog: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Do you want to delete this restaurant</DialogTitle>

                    <DialogActions>
                        <Button onClick={()=> this.setState({openDialog: false})} size='small' variant='outlined' color="primary">
                            No
                        </Button>
                        <Button onClick={()=> this.deleteRestaurant(deleteData)} color="secondary" size='small' variant='outlined' autoFocus>
                            Yes !
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>         
        )
    }
}

export default withRouter(ViewRes);