import React from 'react'
import MaterialTable from 'material-table'
import { Typography } from '@material-ui/core';
import { url } from './constant'

class Users extends React.Component{
    constructor(){
        super();
        this.state={
            column:[
                {
                    "title": 'Name',
                    "field": "userName"
                },
                {
                    "title": 'Email',
                    "field": "email",
                    // "align": "center"
                },
                // {
                //     "title": 'Joined date',
                //     "field": "joinedDate"
                // }
            ],
            data: []
        }
    }

    componentDidMount(){
        fetch(url+'/getUsers',{
            method: "GET"
        })
        .then((res)=> res.json())
        .then((data)=>{
            this.setState({
                data: data['response']
            })
        })


    }

    render(){
        const { data, column } = this.state
        return(
            <div style={{ width: "100%" }} >
                <MaterialTable 
                    columns={column} 
                    data={data} 
                    options={{
                        pageSizeOptions: [10],
                        pageSize: 10
                    }}                    
                    title="Users"                    
                />
            </div>
        )
    }
}

export default Users;