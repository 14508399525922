//export const url = "http://127.0.0.1:5000/"
//export const produrl = "http://127.0.0.1:5000/";

// export const url = "https://api.thefoodguide.co/";
//export const prefixImageUrl = "https://thefoodguide.co/images/";
//export const produrl = "https://api.thefoodguide.co";



//LOCAL
// export const prefixImageUrl = "http://127.0.0.1:3001/images/";

// export const url = "http://127.0.0.1:5000/"

// export const siteurl = "http://127.0.0.1:3001/";

// export const url = "https://api.bestfoodfinders.com/";
// export const prefixImageUrl = "https://bestfoodfinders.com/images/";
// export const produrl = "https://api.bestfoodfinders.com/";

//UAT2022
//bestfoodfinders server
export const prefixImageUrl = "http://bff.bestfoodfinders.com/images/";

export const url = "http://191.101.229.230:8000"
export const produrl = "http://191.101.229.230:8000";
export const siteurl = "http://191.101.229.230:8000";

//local server

// export const prefixImageUrl = "http://localhost:3000/images/";

// export const url = "http://127.0.0.1:5000"
// export const produrl = "http://127.0.0.1:5000";
// export const siteurl = "http://127.0.0.1:5000";


export const area = []

export const cuisines= []

export const formFields = [
    {
        "label": "Restaurant name",        
        "name":"name",
        "validators": ['required'],
        "errorMessages": ['this field is required'],
        "type": "text",
        "noOfRows": 0,
        "multiline": false,
        "placeholder": "Name",
        "required": true,
        "parallel": false        
    },
    {
        "label": "Address",        
        "name":"address",
        "type": "text",
        "noOfRows": 3,
        "placeholder": "Type full address here..",
        "multiline": true,
        "required": true,
        "parallel": false
    },
    {
        "label": "Lattitude",
        "name":"lattitude",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "placeholder": "27.2046",
        "parallel": true,

        "label1": "Longitude",
        "name1":"longitude",
        "type1": "text",
        "noOfRows1": 1,
        "multiline1": false,     
        "placeholder1": "77.4977",   
        "required1": false
    },
    {
        "label": "Short description",
        "name":"shortDesceription",
        "type": "text",
        "noOfRows": 3,
        "multiline": true,
        "placeholder": "Short summary",
        "required": false,     //changed by j aug 2021
        "parallel": false,
    },
    {
        "label": "Phone Number",
        "name":"phone",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": true,
        "placeholder": "+91 69083 29636",
        "parallel": true,

        "label1": "Whatsapp number",
        "name1":"wa_number",
        "type1": "text",
        "noOfRows1": 1,
        "multiline1": false,
        "placeholder1": "+91 6908 29636",
        "required1": false
    },
    {
        "label": "Email",
        "name":"email",
        "type": "email",
        "noOfRows": 1,
        "required": false,
        "placeholder": "restaurant@bff.com",
        "parallel": true,

        "label1": "Website URL",
        "name1":"web_url",
        "type1": "text",
        "noOfRows1": 1,
        "multiline1": false,
        "placeholder1": "http://bestfoodfinders.com/",
        "required1": false
    },
    {
        "label": "Facebook URL",
        "name":"fb_url",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "placeholder": "https://www.foodpanda.sg/restaurant/w3ei/shivam-restaurant",
        "parallel": true,

        "label1": "Instagram URL",
        "name1":"insta_id",
        "type1": "text",
        "noOfRows1": 1,
        "multiline1": false,
        "placeholder1": "https://www.instagram.com/shivam/",
        "required1": false
    },
    {
        "label": "YouTube URL",
        "name":"youtube_url",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "placeholder": "Paste YouTube URL here",
        "parallel": true,

        "label1": "Key to authenticate promotions",
        "name1":"secretKey",
        "type1": "text",
        "noOfRows1": 1,
        "placeholder1": "#bc41%6*",
        "required1": true,
         "multiline1": false        
    }
]

export const formFieldsCntue = []